
import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography, TextField, Container, RadioGroup, Radio, FormControlLabel, Button, Checkbox, FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material';
// import './_SearchResultsList.scss';
// @ts-ignore
import { extractTime12Hr, timeConvert, getFlightLogo } from '../../utils/commonFunctions';
import SeatIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WifiIcon from '@mui/icons-material/Wifi';
import PowerIcon from '@mui/icons-material/Power';
import WatchIcon from '@mui/icons-material/QueryBuilder';
import ImportExportIcon from '@mui/icons-material/ImportExport';
// @ts-ignore
// import FilghtJPG from '../../assets/icons/filghtJPG.jpg';
// @ts-ignore
import iconFilght from '../../assets/icons/icon-travel-fligh.svg';
// @ts-ignore
import verticalIconFilght from '../../assets/icons/vertical-flight-path.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';

import FilterSection from './FilterSection';
import ArrowTooltips from '../common/ArrowTooltips';
import SearchAlertBox from '../common/SearchAlertBox';
import moment from 'moment';
import Drawer from '@mui/material/Drawer';
import Dollars from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';
import FareTypeSlider from '../BrandedFare/FareTypeSlider';
import TravelDeals from './TravelDeals';
import { BrandedFare } from '../BrandedFare/brandedFare';

import { BrandedFareLoadingScreen } from '../Loader';

import ShowUpLift from '../Uplift/ShowUpLift';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import LogosAnimation from '../common/BookWithPaymentComponents/loaders/LogosAnimation';
import { dateChangeAndDaysCount } from '../../utils/util';
import {PROMOCODESTR } from '../../utils/constant';
import ModalComponent  from "../../components/common/Modal/Modal";
declare global {
    interface Window {
      Uplift: any;
    }
  }
const SearchResultsComponent = (props: any) => {
    const { t } = useTranslation();
    const searchRequest = useSelector((state: any) => state.search.searchRequest);
    let searchResultData = props?.getSearchResult;
    let TripInfoData = props?.TripInfoData;
    let [summaryCardData, setSummaryCardData] = useState([]);
    let [showBookButton, setShowBookButton] = useState(false)
    let [showFlightDetails, setShowFlightDetails] = useState([])
    let [appliedFilterArray, setAppliedFilterArray] = useState(
        {
            findCarriers: [],
            noOfStops: [],
            getBaggageData: [],
            getDepartureAirport: [],
            getArrivalAirport: [],
            getLayovers: [],
            findMinMaxValues: { minimumValue: 0, maximumValue: Infinity },
            getDepartureTimeRange: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: "", latestDepartureDateTime: "" },
            getArrivalTimeRange: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: "", latestArrivalDateTime: "" },
            getTotalDuration: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 },
            getLayoverTime: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" }
        });
    let [resetFilter, setResetFilter] = useState(false);
    let [showNoResultCard, setShowNoResultCard] = useState(false)
    let [toggleDrawer, setToggleDrawer]= useState<boolean>(false)
    let [segmentData, setSegmentData] = useState([])
    let refArray = props?.allReference;
    let allData = props?.dataLevelId

    const UPLIFT_CONFIG = process.env.UPLIFT_CONFIG || {};
    const { ENABLE_FEATURE = false } = UPLIFT_CONFIG as { [key: string]: boolean };

    let promoconfig = props?.promoconfig;
    let promoCodeResults = props?.promoCodeResults;
    let promoCodeInProgress = props?.promoCodeInProgress;
    let siteKeyForPromo = false;
    const [promoCode, setPromoCode] = useState('AMAZON20');
    const [isCouponValid, setIsCouponValid] = useState(false);

    if (promoconfig.SHOW_PROMO_ON_SITEKEY.includes(document.title)) { //add site key here like redtag,selloff
        siteKeyForPromo = true;
    }
    const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
    
    const togglePromoModal = () => {
        setIsPromoModalOpen(!isPromoModalOpen);
    };

    let fareData=props.brandedFaresResults

    let inboundData = props?.inboundData
    const matches = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        if(promoCodeResults){
            if (promoCodeResults[promoCode]) {
                const currentDate = new Date();
                const currentDateFormatted = currentDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD

                const { rules, discount } = promoCodeResults[promoCode];
                const couponStartDate = rules.startday;
                const couponEndDate = rules.enddate;

                if (currentDateFormatted >= couponStartDate && currentDateFormatted < couponEndDate) {
                    setIsCouponValid(true);
                }
            }
        }
      }, [promoCodeResults]);

    useEffect(() => {
        setSearchResultData();

        //call promo api to get coupon details here
        if (searchResultData?.length > 0) {
            console.log("searchRequest",searchRequest)
            const segments = searchRequest?.segments;
            const lastSegmentTravelDate = segments[segments.length - 1]?.departureDate;
            let promoRequestData = { 
                coupon: promoCode,
                lastTravelDate: lastSegmentTravelDate, 
                allCarrier: []
              }
        
            props.callPromoCodeApI(promoRequestData);
        }
    }, [searchResultData])

    useEffect(()=>{
        let arr:any=[];
        if(searchRequest?.segments?.length){
            searchRequest?.segments.forEach((item:any)=>{
                arr.push(`${item.departureLocation} ${t('to')} ${item.arrivalLocation}`)
            })
            setSegmentData(arr)
        }
    },[searchRequest])

    function setSearchResultData(runFilters = false){
        if (searchResultData?.length > 0) {
            let data: any = [];
            searchResultData.forEach((searchResult: any) => {
                let obj = {};
                let summary = searchResult.itinerarySummary;
                let noOfStops = searchResult?.layovers?.length || 0;
                let flights = searchResult?.flightSummary;
                let carrier = flights?.[0]?.carrier;
                let departureTime = extractTime12Hr(summary?.departureDateTime);
                let arrivalTime = extractTime12Hr(summary?.arrivalDateTime);
                let totalDuration = timeConvert(searchResult?.totalDurationMinutes);
                let flightLogo = getFlightLogo(carrier)
                let totalFare = searchResult.totalFare
                let layoverData = searchResult?.layovers;
                let layOverTime = 0;
                let cabin = summary?.cabin
                let layoverAirports: any = []
                layoverData && layoverData.forEach((elem: any) => {
                    layOverTime += Number(elem.durationMinutes);
                    if (!(layoverAirports.includes(elem.airport))) {
                        layoverAirports.push(elem.airport)
                    }
                });
                let firstLayover = searchResult?.layovers?.[0]?.airport;
                let firstLayoverCity = searchResult?.layovers?.[0]?.airportCity;
                let operatingCarrier = flights?.[0]?.operatingCarrier;
                let operatingCarrierName = flights?.[0]?.operatingCarrierName;
                let freeBaggageAllowance = summary.freeBaggageAllowance;
                let carrierName = flights?.[0]?.carrierName;

                obj = {
                    ...summary,
                    noOfStops: noOfStops,
                    flights: flights,
                    carrier: carrier,
                    departureTime: departureTime,
                    arrivalTime: arrivalTime,
                    totalDuration: totalDuration,
                    flightLogo: flightLogo,
                    totalFare: totalFare,
                    upliftAmount:searchResult.upliftAmount,
                    layOverTime: layOverTime && timeConvert(layOverTime),
                    firstLayover: firstLayover,
                    operatingCarrier: operatingCarrier,
                    operatingCarrierName: operatingCarrierName,
                    firstLayoverCity: firstLayoverCity,
                    layoverAirports: layoverAirports,
                    departureDateTime: new Date(summary?.departureDateTime),
                    arrivalDateTime: new Date(summary?.arrivalDateTime),
                    totalDurationTime:Number(searchResult?.totalDurationMinutes),
                    layOverDurationTime:layOverTime,
                    flightDetails : flights,
                    layoverData: layoverData,
                    fareID: searchResult?.itineraryFareId,
                    refId: searchResult?.ref,
                    freeBaggageAllowance: freeBaggageAllowance,
                    carrierName: carrierName
                }
                data.push(obj);
            });
            if(runFilters){
                props.getFilters();
            }
            setSummaryCardData(data);
            setShowBookButton(false)
        }

    }

    const setNextLevel = (index: number,flag:boolean,id:number, refId:any) => {
        const {ifNextLevelExist} = props;
        if(ifNextLevelExist){
            props.setNextLevel(refId);
        }else{
            handleDrawerWithId(flag,id)
        }

        const timeoutId = setTimeout(function() {   
            window.Uplift.Payments.load(TripInfoData, function () {
                clearTimeout(timeoutId);
            });
        }, 100);

    }
    const handleFlightDetails = (index: any) => {

        setShowFlightDetails(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    }

    const getFilterData = (data: any) => {

        let flag = true;
        if (appliedFilterArray.findCarriers.length > 0) {
            if (appliedFilterArray.findCarriers.includes(data.carrier)) {
                flag = true
            } else {
                flag = false
                return false
            }
        }

        if (appliedFilterArray.getBaggageData.length > 0) {
            if (appliedFilterArray.getBaggageData.includes("CHARGED_BAGGAGES")) {
                if ((data.hasOwnProperty('chargedBaggageAllowance')) && (data?.chargedBaggageAllowance?.quantity != 0 || data?.chargedBaggageAllowance?.weight != 0)) {
                    flag = true;
                } else {
                    flag = false;
                    return false
                }
            }
            if (appliedFilterArray.getBaggageData.includes("FREE_BAGGAGES")) {
                if ((data.hasOwnProperty('freeBaggageAllowance')) && ((data?.freeBaggageAllowance?.quantity != 0 && data?.freeBaggageAllowance?.quantity !== undefined) || (data.freeBaggageAllowance?.weight != 0 && data.freeBaggageAllowance?.weight !== undefined))) {
                    flag = true;
                } else {
                    flag = false;
                    return false
                }
            }
        }

        if (appliedFilterArray.noOfStops.length > 0) {
            flag = appliedFilterArray.noOfStops.includes(data.noOfStops == 0 ? "NonStop" : data.noOfStops >= 2 ? "2+ Stop" : `${data.noOfStops} Stop`);
            if (!flag) {
                flag = false
                return false;
            }

        }

        if (appliedFilterArray.getDepartureAirport.length > 0) {
            if (appliedFilterArray.getDepartureAirport.includes(data.departureAirport)) {
                flag = true
            } else {
                flag = false
                return false
            }
        }

        if (appliedFilterArray.getArrivalAirport.length > 0) {
            if (appliedFilterArray.getArrivalAirport.includes(data.arrivalAirport)) {
                flag = true
            } else {
                flag = false
                return false
            }
        }

        if (appliedFilterArray.getLayovers.length > 0) {
            let shouldBreak = false;
            if (data.layoverAirports.length > 0) {
                data.layoverAirports.forEach((item: any) => {
                    if (appliedFilterArray.getLayovers.includes(item)) {
                        flag = true
                    } else {
                        flag = false;
                        shouldBreak = true;
                        return false
                    }
                });
                if (shouldBreak) {
                    return false;
                }
            } else {
                return false;
            }
        }

        if (appliedFilterArray.findMinMaxValues.minimumValue > 0 && appliedFilterArray.findMinMaxValues.maximumValue < Infinity) {
            if (data.totalFare < appliedFilterArray.findMinMaxValues.minimumValue || appliedFilterArray.findMinMaxValues.maximumValue < data.totalFare) {
                flag = false
                return false
            } else {
                flag = true
            }
        }

        if (appliedFilterArray.getDepartureTimeRange.earliestDepartureTime != "" && appliedFilterArray.getDepartureTimeRange.latestDepartureDateTime != "") {
            if (data.departureDateTime < appliedFilterArray.getDepartureTimeRange.earliestDepartureTime || appliedFilterArray.getDepartureTimeRange.latestDepartureDateTime < data.departureDateTime) {
                flag = false
                return false
            } else {
                flag = true
            }
        }

        if (appliedFilterArray.getArrivalTimeRange.earliestArrivalDateTime != "" && appliedFilterArray.getArrivalTimeRange.latestArrivalDateTime != "") {
            if (data.arrivalDateTime < appliedFilterArray.getArrivalTimeRange.earliestArrivalDateTime || appliedFilterArray.getArrivalTimeRange.latestArrivalDateTime < data.arrivalDateTime) {
                flag = false
                return false
            } else {
                flag = true
            }
        }

        if (appliedFilterArray.getTotalDuration.minTotalDuration != 0 && appliedFilterArray.getTotalDuration.maxTotalDuration != 0) {
            if (data.totalDurationTime < appliedFilterArray.getTotalDuration.minTotalDuration || appliedFilterArray.getTotalDuration.maxTotalDuration < data.totalDurationTime) {
                flag = false
                return false
            } else {
                flag = true
            }
        }

        if (appliedFilterArray.getLayoverTime.minLayoverDuration != 0 && appliedFilterArray.getLayoverTime.maxLayoverDuration != 0) {
            if (data.layOverDurationTime < appliedFilterArray.getLayoverTime.minLayoverDuration || appliedFilterArray.getLayoverTime.maxLayoverDuration < data.layOverDurationTime) {
                flag = false
                return false
            } else {
                flag = true
            }
        }

        return flag;
    }

    const handleAppliedFilterSateChange = (newState: any) => {
        setAppliedFilterArray(newState);
    };

    const resetFilters = (value: any) => {
        setResetFilter(value)
    }

    useEffect(() => {
        const searchResultscardExists = document.querySelector('.searchResultscard');
        if (searchResultscardExists) {
            setShowNoResultCard(false)
        } else {
            setShowNoResultCard(true)
        }
    });



    const handleToggleDrawer = (open: boolean) => {
        setToggleDrawer(open);
    };
    
    const handleDrawerWithId = async(open: boolean, id: null | number) => {
        
        if (id) {
            let obj={
                "fareCode":id
            }
            props.brandedFaresResultsAPI(obj)

        }
        handleToggleDrawer(open);
    };

    const goToSegment=(id:any,index:number)=>{
        
        props.setNextLevel(id,index);

        const timeoutId = setTimeout(function() {
            window.Uplift.Payments.load(TripInfoData, function () {
                clearTimeout(timeoutId);
            });
        }, 100);
    }

    return (
        <>
            {/* {showBookButton ? <span>Inbound</span> : <span>Outbound</span>} */}
            <Container className="searchResultsList">
                <Grid container spacing={2}>
                    {/* <Grid item sm={4} xs={12}><Button className='searchRes_getupBtn'>Get Updated <MailOutlineIcon /></Button></Grid>
                    <Grid item sm={8} xs={12}></Grid> */}
       {/* below commented code for testing alert modal  */}
                    {/* <Button variant="outlined" onClick={handleClick}>
                        Show Alert
                    </Button>
                    <AlertModal
                        open={open}
                        setOpen={setOpen}
                        title="This is an info message!"
                        btnTitle="Relevant CTA"
                        content="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"
                        icon={InfoIcon}
                        setClass="danger"
                    /> */}
                    <Grid item sm={4} xs={12}>
                        {props.filterData && props.filterData.hasOwnProperty("findMinMaxValues") &&
                            <FilterSection TripInfoData ={TripInfoData} filterData={props?.filterData} handleAppliedFilterSateChange={handleAppliedFilterSateChange} resetFilter={resetFilter} resetFilters={(val: any) => resetFilters(val)} showBookButton={showBookButton} />
                        }
                    </Grid>
                    <Grid item sm={8} xs={12}>
                       
                        {showBookButton ? <p onClick={() => setSearchResultData(true)} style={{cursor: "pointer",margin: "11px 0 13px 0px", "fontSize": "1rem", color: "#1c75bc", "textDecoration": "underline"}}>Select different Outbounds</p> : null}
                        {/* <SearchAlertBox AlertTitle="Carry-on bag included" />
                        <SearchAlertBox AlertTitle="Seat selection included" />
                        <SearchAlertBox AlertTitle="Take off time (12:00 am - 11:30am)" /> */}

                        {summaryCardData && summaryCardData.length > 0 &&
                            <>
                                <Box className="Search_Segment">
                                {allData && allData.map((arr:any,index:number)=>{
                                    return(
                                        <>
                                        <Button  disabled={!(refArray && refArray[index])} className={"Search_SegmentBtn "+((refArray && refArray[index])&& " Search_SegmentBtnActive")}  onClick={()=>goToSegment(refArray[index],index)}>{segmentData[index]}</Button> <ArrowForwardIcon className='Search_arrowSegment'/>
                                        </>
                                    )
                                })}
                                </Box>
                                <Box className="serachRes_TopTitleBox">
                                    <>
                                        <Typography className='searchRes_BestTitle'>{t('Best')} {showBookButton ? "Returning " : t('Departing')} {t('Flights')} <ArrowTooltips text={t("Ranked based on price and convenience")} arrow={true} tooltipClass={"serchResult_tooltip"} Icon={InfoIcon} /> </Typography>
                                    </>
                                    {/* <Button className='searchRes_RecomBtn'>Recommended <ImportExportIcon /></Button> */}
                                </Box>
                                
                                {/* <TravelDeals /> */}
                            </>}

                        {summaryCardData && summaryCardData.map((item, i: number) => {
                           
                            return (
                                getFilterData(item) &&
                                <>
                                    <Box className='searchResultscard'>
                                        <Box onClick={() => setNextLevel(i,true,item.fareID, item.refId)}>
                                            <Box className="serchReu_TopSection">
                                                <Box className="">
                                                    <Box mb={'8px'} className="searchRes__left">
                                                        <Typography component='span' className='searchRes_MainLable'>{item.departureTime}</Typography>
                                                        <Typography component='span' className='searchRes_MainLable' m={'0 5px'}>-</Typography>
                                                        <Typography component='span' className='searchRes_MainLable'>{item.arrivalTime}{
                                                            (() => {
                                                            const daysDifference = dateChangeAndDaysCount(item.departureDateTime, item.arrivalDateTime);
                                                            return daysDifference > 0 ? <sup className='searchRes_AddOneFlight'>+{daysDifference}</sup> : null;
                                                            })()
                                                        }</Typography>
                                                    </Box>

                                                    <Box>
                                                        <Typography component='span' className='searchResults_MainTitle'>{item.departureAirportCity} ({item.departureAirport})</Typography>
                                                        <Typography component='span' className='searchResults_MainTitle' m={'0 5px'}>-</Typography>
                                                        <Typography component='span' className='searchResults_MainTitle'>{item.arrivalAirportCity} ({item.arrivalAirport})</Typography>
                                                    </Box>
                                                    {!matches && <>
                                                        <Typography className='searchRes_MainLable'>{item.totalDuration} &nbsp;

                                                            {item.noOfStops != 0 &&
                                                                <>
                                                                    ({item.noOfStops} stop)
                                                                </>
                                                            }
                                                        </Typography>
                                                    </>}
                                                    <Box className="searchRes__mid" >
                                                        <Box className="searchResults_iconBox">
                                                            <img src={item.flightLogo} className='searchResults_Icon' />
                                                        </Box>
                                                        <Typography className='searchResults__operated'>
                                                            {item.carrierName}{item.carrierName !== item.operatingCarrierName ? <>, {t('Operated by')} {item.operatingCarrierName}</> : null}
                                                        </Typography>
                                                    </Box>

                  

                                                </Box>
                                                {matches &&
                                                    <Box className="text-center searchRes__right"  >
                                                        <Typography className='searchRes_MainLable' mb={'8px'}>{item.totalDuration} &nbsp;

                                                            {item.noOfStops != 0 &&
                                                                <>
                                                                    ({item.noOfStops} {t('stop')})
                                                                </>
                                                            }
                                                            {!item.noOfStops &&
                                                                <>
                                                                    ({t('Non Stop')})
                                                                </>
                                                            }
                                                        </Typography>
                                                        {item.noOfStops != 0 &&
                                                            < >
                                                                {/* <Typography component='span' className='searchResults_MainTitle'>{item.noOfStops} (LHR)</Typography>
                                                            <Typography component='span' className='searchResults_MainTitle' m={'0 5px'}>-</Typography> */}
                                                                <Typography className='searchResults_layOverTime'>{item.layOverTime} {t('in')} {item.firstLayoverCity} ({item.firstLayover})     </Typography>
                                                                {/* <sup className='searchResults_sup'>+1</sup> */}
                                                            </>
                                                        }



                                                        {/* <Button className='searchResults_BookBtn' onClick={() => showInbound(i)}>{showBookButton ? "Book" : "Select Inbound"}</Button> */}
                                                    </Box>}
                                                <Box className="text-right">
                                                    <Box >
                                                        <Typography className='searchResultsClass' component={'span'}>{t(item.cabin)}</Typography>
                                                    </Box>
                                                    <Typography className='searchResults_Price'> ${item.totalFare} </Typography>
                                                    
                                                 {ENABLE_FEATURE?<ShowUpLift  t={t} upliftfare={item.upliftAmount}/>:''}   
                                                    {/* <Typography className='serRust__tirpType'>  roundtrip per person </Typography> */}

                                                </Box>
                                            </Box>
                                            <Box className="searchRes_FromPrice">
                                                {/* <Typography className='serReuslt_from' component={'span'}>
                                                    or from
                                                </Typography>
                                                <Typography className='serReuslt_from' component={'span'}>
                                                    $ 72
                                                </Typography>
                                                <Typography className='serReuslt_from' component={'span'}>
                                                    /mon  <InfoIcon />
                                                </Typography> */}

                                            </Box>
                                        </Box>
                                        <Box className="serRes_sideBox">
                                            <Box className='serarchRes_FlightBtn' onClick={() => handleFlightDetails(i)}>
                                                <Typography className='serResult-seatInfo serResult-seatFlight' component={'span'}>{t('Flight Details')}</Typography>
                                                {showFlightDetails[i] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                            </Box>
                                            <Box className="text-center">
                                            {promoconfig.SHOW_COUPON_CODE && siteKeyForPromo && searchResultData?.length > 0 && isCouponValid &&(
                                                        <>
                                                            <button
                                                                className='btn-unstyled test'
                                                                //onClick={togglePromoModal}>
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Prevents triggering setNextLevel
                                                                    togglePromoModal();   // Toggles the promo modal
                                                                }}>
                                                                <img
                                                                    src={'https://travel-img.s3.amazonaws.com/2024-09-23--1727104858759147300---RT---%2420-Amazon-Gift-Card-on-Flights_20_102-x-32-Icon-%281%29.jpg'}
                                                                />
                                                            </button>
                                                        <ModalComponent open={isPromoModalOpen} onClose={togglePromoModal}>
                                                            <Typography variant="h6">{PROMOCODESTR.PROMOTITLE}</Typography>
                                                            <br />
                                                            <img className='w-100' src={'https://travel-img.s3.amazonaws.com/2024-10-03--1727960746218347300---RT---%2420-Amazon-Gift-Card-on-Flights_Modal-v2-EN.jpg'} 
                                                            />
                                                        </ModalComponent>
                                                    </>
                                                )}
                                            </Box>
                                            <Box className=''>

                                                {matches ? <>

                                                    {/* <Typography className='serResult-seatInfo' component={'span'}>Seat Selection Included • Carry-On Bag Included •</Typography>
                                                    <Typography className='serResult-seatInfo serResult-seatRed' component={'span'}> Check-In Bag Not Included</Typography> */}
                                                </> : <>
                                                    <SeatIcon />
                                                </>}
                                            </Box>
                                            <Box>
                                                {
                                                ((item?.freeBaggageAllowance?.quantity != 0 && item?.freeBaggageAllowance?.quantity !== undefined) || (item.freeBaggageAllowance?.weight != 0 && item.freeBaggageAllowance?.weight !== undefined))
                                                ?    
                                                <Typography className='serResult-seatInfo' component={'span'}> Check-In Bag Included </Typography> : <Typography className='serResult-seatInfo serResult-seatRed' component={'span'}> Check-In Bag Not Included </Typography>}  </Box>
                                        </Box>


                                        <Box>
                                            {showFlightDetails[i] && <>
                                                <Grid container>
                                                    <Grid item sm={12} xs={12}>
                                                        <Box className='searchResultscard seaResults_Innercard'>

                                                            {item.flightDetails.map((flight:any,index:number) => {
                                                                // flight.layovers = flight?.layovers ?? {}
                                                                let { departureDateTime, arrivalDateTime, durationMin }=flight
                                                                // let departDateTime=flight?.departureDateTime;
                                                                // let arrivalDateTime=flight?.arrivalDateTime;
                                                                let formattedDepartureDate = departureDateTime && moment(departureDateTime).format('MMMM ddd D, YYYY');
                                                                let formattedArrivalDate = arrivalDateTime && moment(arrivalDateTime).format('MMMM ddd D, YYYY');
                                                                let departureTime = departureDateTime && extractTime12Hr(departureDateTime);
                                                                let arrivalTime = arrivalDateTime && extractTime12Hr(arrivalDateTime);
                                                                let layoverData = item?.layoverData?.[index];
                                                                let layOverTime = layoverData && layoverData?.durationMinutes && timeConvert(layoverData?.durationMinutes);
                                                                let layoverCity = layoverData && layoverData?.airportCity;
                                                                let layoverAirport = layoverData && layoverData?.airport;
                                                                let totalDuration = durationMin && timeConvert(durationMin)
                                                                return(
                                                                <Box>
                                                                    <Box className="serchReu_TopSection serchReu_innerCard">
                                                                        <Box className="serchReu_innerDetails">
                                                                                <Box mb={'8px'} className="serchReu_innerLeft">
                                                                                    <Box>
                                                                                       <Typography component='span' className='searchRes_MainLable'>{departureTime} </Typography>
                                                                                       <Typography component='span' className='searchResults_Days'>{formattedDepartureDate}</Typography>
                                                                                    </Box>
                                                                                    <Typography className='searchResults_MainTitle'>  {flight?.departureAirportCity} ({flight?.departureAirport}) </Typography>
                                                                                </Box>
                                                                          
                                                                      
                                                                        <Box className="serachRes_TotalTime">
                                                                            <Typography className='serchRes_layTime'>{totalDuration}</Typography>
                                                                            <Box>
                                                                                <img src={matches ? iconFilght :verticalIconFilght} className='serachRes_iconFilght' />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box className="serchReu_innerRight">
                                                                            <Box mb={'8px'}>

                                                                                <Typography component='span' className='searchRes_MainLable'>{arrivalTime}  </Typography>
                                                                                <Typography component='span' className='searchResults_Days'>{formattedArrivalDate}</Typography>
                                                                            </Box>
                                                                            <Typography className='searchResults_MainTitle'>  {flight?.arrivalAirportCity} ({flight?.arrivalAirport}) </Typography>
                                                                            {/* <Typography className='serRust__tirpType'>multi-city per person  </Typography> */}

                                                                        </Box>
                                                                        </Box>
                                                                        <Box className="searchRes_typeSection">
                                                                                <Typography component='span' className='searchRes_typeClass'> {t(item?.cabin)} | {flight.operatingCarrier} {flight?.number} </Typography> 
                                                                            </Box>
                                                                    </Box>
                                                                        {layoverData &&
                                                                            <Box className="searchRes_Layover">
                                                                                <WatchIcon className='WatchIcon' />  <Typography component={'span'} className='serchRes_layTitle'>{t('Layover')}: {layOverTime} {t('in')} {layoverCity} ({layoverAirport})</Typography>
                                                                            </Box>
                                                                        }

                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    </Grid>
                                                    {/* <Grid item sm={2} xs={12} className='serResults_fareBtnBox'>
                                                        
                                                        <Button onClick={()=>handleDrawerWithId(true,item.fareID)} className='serResults_SetFareBtn'>Select Fare</Button>
                                                    </Grid> */}
                                                </Grid>

                                            </>}
                                        </Box>

                                    </Box>

                                </>

                            )
                        })}

                        {summaryCardData && summaryCardData.length > 0 && showNoResultCard &&
                            <Box className='searchResult_NoResult'>
                                {t('You have applied too many filters')} {t('Click')} <a href='#' onClick={() => resetFilters(true)}>{t('here')}</a> {t('to View all results')}
                            </Box>
                        }


                    </Grid>

                </Grid>

            </Container>
            {(!(props.brandedFaresLoader)) && <BrandedFare
                toggleDrawer={toggleDrawer}
                setToggleDrawer={handleToggleDrawer}
                fareData={fareData} 
                pricingCall={(fareID) => props.pricingAPI(fareID)}
                triptype={props?.triptype}
            />}
            {(props.brandedFaresLoader) && <BrandedFareLoadingScreen />}

        </>
    )
}

export default SearchResultsComponent