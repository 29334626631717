import react from "react";
import moment from "moment";

export class QuoteRequestTranslation {
  
    // auth object
    private auth: Record<string, any> = {};
    // language code
    private language: string = "en"; // Initialized to en
    // familyrate code
    private familyrate: string = "NO"; // Initialized to No
    // current province code
    private province: string = "ON"; // Initialized to ON
    // holds passenger array
    private passengers: Record<string, any> = {}; // Initialized as an empty array
    // Holds booking itinarary details
    private booking: Record<string, any> = {}; // Initialized as an empty object

    private quoteData: Record<string, any> = {}; // Initialized as an empty object

    private paxInsArray: any = []; //Initialized array to store pax wise insurance details
    private travellerArray: any = []; //Initialized array to store traveller form data

    private bookQuoteRequest: Record<string, any> = {}; 

    constructor(pricingResults: any) {
        if(pricingResults?.segments?.length >0){
            this.authData();
            this.language = "en";
            this.familyrate = "NO";
            this.province =  "ON";
            this.bookingData(pricingResults);
            this.passengerData(pricingResults);
        }
    }

    private authData(){
        const insuranceAuthData = process.env.INSURANCE_AUTHDATA ? JSON.parse(process.env.INSURANCE_AUTHDATA) : {};
        this.auth = insuranceAuthData;
    }

    private bookingData(pricingResults){
        // Destructure with default values
        const { segments = [] } = pricingResults || {};
        if (segments.length < 1) {
            console.error("No segments found in pricing results.");
            return;
        }

        const [firstSegment = {}, lastSegment = {}] = [segments[0], segments[segments.length - 1]];
        const { flights: firstSegmentFlights = [] } = firstSegment;
        const { flights: lastSegmentFlights = [] } = lastSegment;

        if (firstSegmentFlights.length < 1 || lastSegmentFlights.length < 1) {
            console.error("Flight information is missing in the segments.");
            return;
        }

        const [departureFlight = {}] = firstSegmentFlights;
        const [returnFlight = {}] = lastSegmentFlights;
        
        const { departureAirport: departureCity = '' } = departureFlight;
        const { departureAirport: destinationCity = '' } = returnFlight;
        const departureDate = this.convertDataFormat(departureFlight.departureDateTime || '');
        const returnDate = this.convertDataFormat(returnFlight.departureDateTime || '');
    
        this.booking ={
            "departureCity": departureCity,
            "destinationCity": destinationCity,
            "departureDate": departureDate,  //20241023
            "returnDate": returnDate //20241023
        }
    }

    private passengerData(pricingResults){
        
        let paxFare = pricingResults.itineraryFare.paxFares;
       
        let passengerIdCounter = 1; // To assign unique passenger IDs
        let passengerArr = [];
        let newpassengerArr = [];
       
        
        let newPaxFare = paxFare.flatMap(elem => Array(elem.passengerDetails.quantity).fill(elem));
console.log("newPaxFare",newPaxFare)
        newPaxFare.forEach((fare,index)  => {
            const baseAmount = fare?.sellingBaseAmount?.amount ? fare.sellingBaseAmount.amount: fare?.fareBreakDown?.sellingBaseAmount?.amount;
            const taxesAndFees = fare.totalTaxes.amount;
            
            passengerArr.push({
            passengerId: index, // index passenger ID for each passenger
            firstName: " ", // Placeholder, as no names are provided
            lastName: "", // Placeholder, as no names are provided
            birthDate: "",
            tripValue: baseAmount + taxesAndFees, //fare.totalAmount.amount.toFixed(2) // Using totalAmount as tripValue
            });
            
        });

        this.passengers = {passenger:passengerArr};
    
    }

    set setProvince(provinceCode: string) {
        this.province = provinceCode;
    }

    get getProvince() {
        return this.province;
    }

    set setPassengerData(passengerRequest: any){
        this.passengers = {passenger:passengerRequest};
    }

    set setQuoteData (quoteData) {
        if (quoteData?.quote?.passengers?.passenger.length > 0) {
            quoteData.quote.passengers.passenger.forEach(passenger => {
                passenger.plans.plan.forEach(plan => {
                    plan.planPrice = plan.planTotal;
                });
            });
            this.quoteData = quoteData;
        }else{
            this.quoteData = quoteData;
        }
    };

    get getQuoteData(){
        return this.quoteData;
    }
    
    private convertDataFormat(dateString){
        return dateString.split('T')[0].replace(/-/g, ''); // Extract date portion
    }

    set setPaxInsArray(paxArray){
        this.paxInsArray = paxArray;
    }

    get getPaxInsArray(){
        return this.paxInsArray;
    }

    set setTravellerArray(traveller){
        this.travellerArray = traveller;
    }

    get getTravellerArray(){
        return this.travellerArray;
    }

    get getInsbreakdown(){
        let insbreakdown: any = {};
        this.paxInsArray.forEach((paxItem: any,index: number) => {
        const passenger = this.travellerArray[index];

        if (!insbreakdown[paxItem.insCode]) {
            insbreakdown[paxItem.insCode] = {
            adult: passenger.paxType === 'Adult' ? 1 : 0,
            child: passenger.paxType !== 'Adult' ? 1 : 0,
            planname: paxItem.planName,
            perDay: paxItem.perDay,
            amount: paxItem.amount,
            childamount: paxItem.childamount,
            base: paxItem.base,
            tax: paxItem.tax,
            };
        } else {
            if (passenger.paxType === 'Adult') {
            insbreakdown[paxItem.insCode].adult += 1;
            } else {
            insbreakdown[paxItem.insCode].child += 1;
            insbreakdown[paxItem.insCode].childamount = paxItem.childamount;
            }
        }
        });
        return insbreakdown;
    }

    get getBookQuoteRequest(){
        return this.bookQuoteRequest;
    }

    set setBookQuoteRequest(bookingResult){
        console.log("bookingResult here",bookingResult)
        const {recordLocator = '', bookRequest = {}} = bookingResult;
        let bookAuth  = { ...this.auth, "quoteId": this.quoteData.quote.quoteId, "agentcode": ''};
        let tripDetails = this.getTripDetails(recordLocator);
        let addressDetails = this.getAddressDetails(bookRequest,this.travellerArray);
        let plansDetails = this.getPlansDetails(this.paxInsArray);
        let paymentDetails = this.getPaymentDetails(bookRequest);
        
        this.bookQuoteRequest = {
            "auth" : bookAuth,
            "language" : this.language,
            "trip" : tripDetails,
            "address": addressDetails,
            "plans": plansDetails,
            "payment": paymentDetails,
        }
    }

    private getTripDetails(recordLocator){
        let tripPassengersArray = this.getTripPassengers(this.travellerArray);
        let tripdata =  {
            "departure": this.booking.departureDate,
            "return": this.booking.returnDate,
            "origin": this.booking.departureCity,
            "destination": this.booking.destinationCity,
            "bookingNumber": recordLocator,
            "passengers": tripPassengersArray
        } 
        return tripdata;
    }

    private getTripPassengers(travellerArray){
        const tripPassengers = travellerArray.map((pax, index) => ({
            id: index + 1, // Index passenger ID for each passenger
            dob: `${pax.yearOfBirth}-${pax.monthOfBirth}-${pax.dayOfBirth}`,
            name: {
                first: pax.firstName,
                last: pax.lastName
            }
        }));
        return tripPassengers;
    }

    private getPaymentDetails(bookRequest){
       let totalAmount =  this.paxInsArray.reduce((sum, option) => sum + Number(option.amount), 0);
        let paymentData =  {
            "amount": totalAmount,
            "number": bookRequest.number,
            "expiry": {
                "month": bookRequest.expiryMonth,
                "year": bookRequest.expiryYear
            },
            "name": bookRequest.nameOnCard,
            "cvv": bookRequest.cvv
        }
        return paymentData;
    }

    private getAddressDetails(bookRequest,travellerArray){
        let addressData={
            "street": bookRequest.streetAddress,
            "city": bookRequest.city,
            "province": bookRequest.province,
            "postal": bookRequest.postalCode,
            "phone": travellerArray[0].mobileNumber,
            "email": travellerArray[0].email
        }
        return addressData;
    }

    private getPlansDetails(paxInsArray){
        let plansData = paxInsArray.flatMap((item, index, arr) => {
            if (index === arr.findIndex(i => i.insCode === item.insCode)) {
                return {
                    code: item.insCode,
                    passengers: arr
                        .filter(i => i.insCode === item.insCode)
                        .map(i => ({ id: i.id.toString() }))
                };
            }
            return [];
        });
        
        return plansData;
    }
}