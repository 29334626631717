import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

import { useErrorHandler } from '../common/ErrorHandler';
import { DEFAULT_FUNCTION } from '../../utils/constant';

let ErrorHandler: any = () => {};
let cb: (() => void) | null = null; // Synchronous callback storage

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 343,
  bgcolor: 'background.paper',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '10px',
  p: '1rem 1.5rem 1.5rem 1.5rem',
};

const ErrorNotifier: React.FC = ({ handleCloseFunc = DEFAULT_FUNCTION }: any) => {
  const { error, open, setOpen, clearError, handleError } = useErrorHandler();

  const Icon = error?.icon;

  ErrorHandler = (...args: any) => {
    handleError(args[0], args[1], args[2], args[3], args[4], args[5]);

    if (args[6] && typeof args[6] === 'function') {
      cb = args[6];
    } else {
      cb = null;
    }
  };

  // Handle close logic
  const handleClose = (e: any = null) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (cb) {
      const tOut = setTimeout(() => {
        tOut && clearTimeout(tOut);
        cb();
        cb = null;
      })
    }
    setOpen(false);
    clearError();
    handleCloseFunc();
  };

  return (
    <>
      {error?.type === 'snackbar' && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error?.message}
          </MuiAlert>
        </Snackbar>
      )}

      {error?.type === 'alertModal' && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-modal-title"
          aria-describedby="alert-modal-description"
        >
          <Box sx={style} className={"alert_main " + error?.className}>
            <Box textAlign={'right'}>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>

            <Box className="alert_body">
              <Box className="alert_header">
                {Icon && <Icon className="alert_icon" />}
                <Typography id="alert-modal-title" variant="h6">
                  {error?.title || ''}
                </Typography>
              </Box>
              <Box>
                <Typography className="alert_content" id="alert-modal-description">
                  {error?.message || ''}
                </Typography>
                <Button className="alert_btn" onClick={()=>handleClose()}>{error?.btnTitle}</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ErrorNotifier;
export { ErrorHandler };
