import { useState } from 'react';
import {getSeatMapDetails} from "../services";

const SeatMapAPI = () => {
    const [seatMapData, setseatMapData] = useState(null);
    const [error, setError] = useState(false);
    const [apiStatus, setApiStatus] = useState('init');
    const getSeatMap = async(fareId:string, flightId: string, token:string) => {
        try {
            setError(false)
            setApiStatus('pending');
            const res = await getSeatMapDetails({fareId, flightId, token});
            if (res?.results?.error) {
                setError(error);
                setApiStatus('rejected');
            }
            setApiStatus('resolved');
            setseatMapData(res)
        } catch (error) {
            setError(error);
            setApiStatus('rejected');
        }
    }
    return { getSeatMap, seatMapData, apiStatus, error };
};

export { SeatMapAPI };