import { AxiosRequestConfig, AxiosResponse } from "axios";
import { b2cService } from "../axiosServices";

interface RequestBodyInterface {
    fareId: String;
    flightId: String;
    token: String
}

const getSeatMapDetails = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { fareId, flightId, token } = postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": " f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype": "CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };

    let data: any = await b2cService.post(`seatMapping/seatMap?token=${token}`, { fareId, flightId }, requestConfig);

    return data.data;
}

export { getSeatMapDetails };