import React, { useEffect, useState } from 'react';
import { Popover, TextField, Box, Typography, Grid, InputAdornment } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';

interface ChildrenComponentInterface {
    gridOptions?: {
        item: boolean;
        sm: number;
        xs: number;
    };
    setChildCount: (value: number) => void;
    setInfantCount: (value: number) => void;
    setChildAge: (ages: number[]) => void;
    childCount: number;
    infantCount: number;
    childAge: number[];
}

function SearchWidgetChildrenComponent({
    setChildCount,
    setInfantCount,
    setChildAge,
    gridOptions,
    childAge = [],
    infantCount = 0,
    childCount = 0,
}: ChildrenComponentInterface) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [innerValue, setInnerValue] = useState<string>(childCount.toString());
    const [subsequentSelectValues, setSubsequentSelectValues] = useState<(number | null)[]>(childAge);
    const childrenValue = Array.from({ length: 9 }, (_, index) => index);    
    const id = 'your-children-id';

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleInnerChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setInnerValue(value);
        const numberOfChildren = parseInt(value);
        setSubsequentSelectValues(Array(numberOfChildren).fill(null));
    };

    const handleSubsequentChange = (event: SelectChangeEvent, index: number) => {
        const age = Number(event.target.value);
        setSubsequentSelectValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[index] = age;
            return updatedValues;
        });
    };

    useEffect(() => {
        setChildAge(subsequentSelectValues);
    }, [subsequentSelectValues])

    useEffect(()=>{
        let child=0;
        let infant=0;   
        for(let i=0;i<parseInt(innerValue);i++){
            if(subsequentSelectValues && (subsequentSelectValues[i] || subsequentSelectValues[i] === 0) && subsequentSelectValues[i] <2 ){
                infant++
            }else{
                child++
            }

        }
        setChildCount(child);
        setInfantCount(infant)
    },[subsequentSelectValues,innerValue])

    useEffect(() => {
        if (childAge.length) {
            setSubsequentSelectValues(childAge);
            setInnerValue(childAge.length.toString());
        }
    }, [childAge]);

    const renderSubsequentSelects = () => {
        return subsequentSelectValues.map((value, index) => (
            <Grid item sm={6} xs={6} key={index}>
                <Typography className="serachChildrenSub">{t('Child')} {index + 1}</Typography>
                <Select
                    value={value !== null ? value.toString() : ''}
                    onChange={(event) => handleSubsequentChange(event, index)}
                    fullWidth
                >
                    {[...Array(12)].map((_, i) => (
                        <MenuItem key={i} value={i}>
                            {i}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        ));
    };

    return (
        <Grid {...gridOptions}>
            <Typography className="search_FieldTitle search_FieldChildren">{t('Children')}</Typography>
            <TextField
                aria-describedby="children-select-popover"
                value={innerValue}
                onClick={handleClick}
                className="SearchWidget__Input"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <ArrowDropDownIcon />
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
            <Popover
                id="children-select-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className="serachWidget_Children">
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <Typography className="serachChildLablMain">{t('Children')}</Typography>
                            <Select
                                value={innerValue}
                                onChange={handleInnerChange}
                                className="serachWidgetSelect"
                                fullWidth
                            >
                                {childrenValue.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>

                            {Number(innerValue) > 0 && (
                                <Typography className="serachChildLablMain serachChildTitleSub">
                                    {t("Child's age upon return")}
                                </Typography>
                            )}
                        </Grid>

                        {renderSubsequentSelects()}
                    </Grid>
                </Box>
            </Popover>
        </Grid>
    );
}

export default SearchWidgetChildrenComponent;
