import { v4 as uuidV4 } from 'uuid';
import React, { useRef, useState, useEffect, SyntheticEvent } from 'react';
import { Container, Grid, Paper, Typography, Button, Box, Link, FormControlLabel, Checkbox, Alert } from '@mui/material';
import TravellerForm, { TravellerData } from '../common/BookWithPaymentComponents/PassengerDetails';
import FlightDetails from '../common/BookWithPaymentComponents/FlightDetails';
import PaymentComponent, { PaymentComponentHandle } from '../common/BookWithPaymentComponents/Payment';
import PriceSummaryComponent from '../common/BookWithPaymentComponents/PriceSummary';
import GroupsIcon from '@mui/icons-material/Groups';
import { CheckBox, Segment } from '@mui/icons-material';
import moment from 'moment';
import PaymentIcon from '@mui/icons-material/Payment';
import { Stepper } from '../Stepper/Stepper';
import CircularProgress from '@mui/material/CircularProgress';
import DollerIcon from '@mui/icons-material/MonetizationOn';
import { PASSENGERTYPE, TRIPTYPE, INSURANCEPLANCODE, INSURANCESTR,paymentlabel } from '../../utils/constant';

// @ts-ignore
import { convertCamelCase } from "../../utils/commonFunctions";
import FlightDetailsLoader from '../common/BookWithPaymentComponents/loaders/FlightDetailsLoader';
import PriceSummaryLoader from '../common/BookWithPaymentComponents/loaders/PriceSummaryLoader';
import CustomizeYourBooking from '../common/BookWithPaymentComponents/CustomizeYourBooking';
import InsuranceForPax from '../common/BookWithPaymentComponents/InsuranceForPax';
import InsuranceLoader from '../common/BookWithPaymentComponents/loaders/InsuranceLoader';
import PaymentWithUplift from '../Uplift/PaymentWithUplift';
import { getAddOns } from './helper';
import { getFlightsFromSegments } from '../common/SeatMap/helper';
import { calculateAgeAtDeparture, emailValidation, isFutureDate } from '../../utils/util';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

import GiftCard from '../GiftCard/GiftCard';
import { ErrorHandler } from '../Error';
import UpliftPriceComponent from '../common/BookWithPaymentComponents/upliftPriceComponent';
import CouponCodeComponent from '../CouponCode/CouponCode';
interface Traveler {
  last_name: string;
  first_name: string;
  id: number;
}

interface Itinerary {
  departure_apc: string;
  arrival_apc: string;
  departure_time: string;
}

interface AirReservation {
  itinerary: Itinerary[];
}

interface TripInfoData {
  air_reservations: AirReservation[];
  travelers: Traveler[];
}
interface BookWithPaymentInterface {
  pricingData: any;
  bookWithPaymentAPI: any;
  pricingInProgress: boolean;
  countriesData: any;
  stateAPI: any;
  stateData: any;
  updateQuoteRequest: (newRequest: any, actionStr: string) => void;
  quoteRequest: any;
  quoteInProgress: boolean;
  triptype: any;
  TripInfoData:TripInfoData;
  frequentFlyerData:[];
  handlePaymentMethod:any;
  paymentMethod:string;
  SeatMapAPI:any;
  token: string;
  btnLoader: boolean;
  GiftCardAPI:any;
  giftCardResponse:any;
  giftCardLoding:boolean;
  preAuthGiftCardAPI:any;
  preAuthStatus:boolean;
  navigateToSearch: Function;
  correlationId:string;
  promoconfig: any;
  callPromoCodeApI:any;
  promoCodeResults:any;
  promoCodeInProgress:boolean;
}

interface Flight {
  id: string;
  number: string;
  operatingFlightNumber: string;
  carrier: string;
  carrierName: string;
  operatingCarrier: string;
  operatingCarrierName: string;
  departureAirport: string;
  departureAirportCity: string;
  arrivalAirport: string;
  arrivalAirportCity: string;
  arrivalDateTime: string;
  departureDateTime: string;
  aircraftType: string;
  fareBasisCode: string;
}

interface SegmentInt {
  id: string;
  flights: Flight[];
  cabin: {
    type: string;
    flightIds: string[];
  }[];
  brand: {
    code: string;
    brandName: string;
    flightIds: string[];
  }[];
}

export interface AddOn {
  flightNumber: string,
  seat: {
    seatNumber: string
  },
  carrier: string
}

interface TravellerPayload {
  title: string;
  paxType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  addOns: AddOn[];
  phone?: string; // Optional
  type?: string;  // Optional
  email?: string; // Optional
  memberships?:any //Optional 
};

interface PaxCount {
  adult: number;
  child: number;
  infantinlap: number;
}

interface SelectedSeat {
  flightId: string;
  seatId: string;
  travellerId: string;
}

interface FlightSeats {
  selectedSeats: SelectedSeat[];
}


interface PriceData {
  itineryTotal: number;
  giftCardTotal: number;
  finalAmount:number;
}
export interface SelectedSeats {
  [key: string]: FlightSeats;
}

interface CouponRequest {
  couponName: string;
  couponPrice: number; 
}

const BookWithPaymentComponent: React.FC<BookWithPaymentInterface> = ({ pricingData,paymentMethod,TripInfoData,handlePaymentMethod, bookWithPaymentAPI, pricingInProgress, countriesData, stateAPI, stateData, quoteRequest = null, quoteInProgress, triptype, frequentFlyerData,GiftCardAPI,giftCardResponse = null,giftCardLoding, btnLoader, SeatMapAPI, token,preAuthGiftCardAPI,preAuthStatus, navigateToSearch,correlationId, promoconfig, promoCodeResults, promoCodeInProgress, ...props }) => {
  const { t } = useTranslation();
  // Destructure pricingData to get itineraryFare and segments
  const { itineraryFare = {}, segments = [] } = pricingData || {};

  const [itineraryFareState, setItineraryFare] = useState(itineraryFare);
  const [segmentsState, setSegments] = useState<SegmentInt[]>(segments);
  const paymentRef = useRef<PaymentComponentHandle>(null);
  const [segmentData, setSegmentData] = React.useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showInvalidErrorMessage, setShowInvalidErrorMessage] = useState(false);
  const [matchedSameName, setMatchedSameName] = useState(false);
  const [isUNMR, setIsUNMR] = useState(false);
  const [isUNMRAccepted, setIsUNMRAccepted] = useState(false);
  const [checkBoxValidation, setCheckboxValidation] = useState(false);
  const [newGiftCardResult,setnewGiftCardResult] = useState(giftCardResponse);
  let now = new Date();
  let nowM = moment(now).format("MMMM DD, YYYY")
  const [departureDate, setDepartureDate] = React.useState(nowM)
  const [arrivalDate, setArrivalDate] = React.useState(nowM)
  const [lastTravelDate, setLastTravelDate] = React.useState(nowM)
  const [allSegmentCarrier, setAllSegmentCarrier] = useState([]);
  const [paxCount, setPaxCount] = React.useState<PaxCount>({
    adult: 0,
    child: 0,
    infantinlap: 0,
  });

  const [NewPreAuthStatus,setNewPreAuthStatus] = useState(preAuthStatus);
  const [newQuoteRequest, setNewQuoteRequest] = React.useState(quoteRequest);
  const [isPaxUpdated, setIsPaxUpdated] = useState(false);
  const [insuranceTotalAmount, setInsuranceTotalAmount] = useState(null);
  const [insuranceBreakdown, setInsuranceBreakdown] =  useState({});
  const [virtualCreditcardData,setVirtualCreditcardData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [priceData, setpriceData] = useState<PriceData>({ itineryTotal: 0, giftCardTotal: 0,finalAmount:0 });

  const [giftCardPurchase,setgiftCardPurchase]= useState(null);
  const [bookPromoCodeData, setBookPromoCodeData] = useState<CouponRequest>({ couponName: '', couponPrice: 0});
  const [promoCodeData, setPromoCodeData] = useState(promoCodeResults);

  let siteKeyForPromo = false;
  if (promoconfig.SHOW_PROMO_ON_SITEKEY.includes(document.title)) { //add site key here like redtag,selloff
    siteKeyForPromo = true;
  }

  const initialTravellerState: TravellerData = {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    countryCode: '',
    mobileNumber: '',
    dayOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: '',
    gender: '',
    paxType: 'Adult',
    index: 1,
    memberships: [],
    errors: {
      title: false,
      firstName: false,
      lastName: false,
      dayOfBirth: false,
      monthOfBirth: false,
      yearOfBirth: false,
      validDOB: false,
      gender: false,
      email: false,
      countryCode: false,
      mobileNumber: false,
      isFutureDateForInfant: false,
      programId:  false,
      programNumber: false
    }
  };

  const [selectedSeats, setSelectedSeats] = useState<SelectedSeats>({});
  // const selectedSeats = {
  //   '2': {
  //     selectedSeats: [{ flightId: '2', seatId: '1A', travellerId: '123123-123123-adada-123'}]
  //   }
  // }
  
  const updateSelectedSeat = (flightId: string, letter: string, row: number) => {
    console.log('passengers', { flightId, letter, row });
    const selectedFlightSelectedSeats = ((selectedSeats[flightId] ?? {}).selectedSeats ?? [])

    if (selectedFlightSelectedSeats.some((seat: any) => seat.seatId === `${row}${letter}`)) {
      setSelectedSeats({
        ...selectedSeats,
        [flightId]: {
          selectedSeats: selectedFlightSelectedSeats.filter((seat: any) => seat.seatId !== `${row}${letter}`)
        }
      });
      return;
    }

    // Compare travellers and selectedSeats[flightId].selectedSeats
    const remainingTravellers = travellers.filter((traveller: any) => 
      !selectedFlightSelectedSeats.some((seat: any) => seat.travellerId === traveller.id)
    );

    if (remainingTravellers.length === 0) {
      return;
    }
    setSelectedSeats({
      ...selectedSeats,
      [flightId]: {
        selectedSeats: [
          ...selectedFlightSelectedSeats,
          {
            flightId,
            seatId: `${row}${letter}`,
            travellerId: remainingTravellers[0].id
          }
        ]
      }
    });
  }
  
  const handleAvailableGiftCard = (giftcardData: any) => {

    if (giftcardData) {
        setgiftCardPurchase(giftcardData);
    }
};
  const handleInsuranceChangeCallback = (totalamount: any, totalbase: any, paxOptionArray: any) => {
    let paxData = {
      paxOptionArray : paxOptionArray,
      travellers: travellers
    }
    props.updateQuoteRequest(paxData,INSURANCESTR.paxInsChange);
    
    const insbreakdown = newQuoteRequest.getInsbreakdown;
    setInsuranceBreakdown(insbreakdown);
    setInsuranceTotalAmount(totalamount.toFixed(2));
  }

  const handlePriceSummaryData = (priceData: PriceData) => {
    setpriceData(priceData);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handlePromoPrice = (promoPrice: number) => {
    let newPromoequest = {
      ...bookPromoCodeData,
      couponPrice: promoPrice
    }
    setBookPromoCodeData(newPromoequest)
  }

  const handlePromoCode = (promocode: string) => {
    if(promocode){

      let newPromoequest = {
        ...bookPromoCodeData,
        couponName: promocode
      }
      setBookPromoCodeData(newPromoequest)
      
      let promoRequestData = { 
        coupon: promocode,
        lastTravelDate: lastTravelDate, 
        allCarrier: allSegmentCarrier
      }

      props.callPromoCodeApI(promoRequestData);
    }
  }

  useEffect(() => {
    setPromoCodeData(promoCodeResults);
  }, [promoCodeResults]);

  useEffect(() => {
    if(quoteRequest){
      setNewQuoteRequest(quoteRequest);
    }
  }, [quoteRequest]);

  useEffect(()=>{
      setNewPreAuthStatus(preAuthStatus)
  },[preAuthStatus])

  useEffect(()=>{
    if(giftCardResponse){
      setnewGiftCardResult(giftCardResponse);
    }

  },[giftCardResponse])

  const handleProvinceChange = (provinceCode: string) => {
    props.updateQuoteRequest(provinceCode,INSURANCESTR.provinceChange);
  };

  const initializeTravellers = (passengerDetails: any) => {
    const data: PaxCount = { ...paxCount };
    const passengerType = passengerDetails.type.toLowerCase() as keyof PaxCount;
    data[passengerType] = passengerDetails.quantity;
    setPaxCount(data);
    
    return Array.from({ length: passengerDetails.quantity }, (_, index) => ({ ...initialTravellerState, paxType: passengerDetails.type, index: index + 1, id: uuidV4() }));
  };

  const [travellers, setTravellers] = useState<TravellerData[]>([initialTravellerState]);

  const updatePaxRequest = (index: number, updatedTravellers: any) => {
    const updatedPassengers = [...quoteRequest.passengers.passenger];

    const birthDate = updatedTravellers.dayOfBirth && updatedTravellers.monthOfBirth && updatedTravellers.yearOfBirth
          ? `${updatedTravellers.yearOfBirth}${moment().month(updatedTravellers.monthOfBirth).format("MM")}${updatedTravellers.dayOfBirth}`
          : "";
    updatedPassengers[index] = {
      ...updatedPassengers[index],
      firstName: updatedTravellers.firstName,
      lastName: updatedTravellers.lastName,
      birthDate: birthDate
    }
    props.updateQuoteRequest(updatedPassengers, INSURANCESTR.paxChange);
  }
  
  useEffect(() => {
    setItineraryFare(pricingData?.itineraryFare);
    setSegments(pricingData?.segments || []);
  }, [pricingData])

  useEffect(() => {
    if (itineraryFareState?.paxFares) {
      setTravellers([]);
      const initialTravellers = itineraryFareState.paxFares.flatMap(({ passengerDetails }: any) => initializeTravellers(passengerDetails));
      setTravellers(initialTravellers);
      processPaxCount(itineraryFareState)
    }
  }, [itineraryFareState]);

  const processPaxCount = (itineraryFareState: any) => {
    const {paxFares} = itineraryFareState;
    const data: PaxCount = { ...paxCount };
    paxFares.forEach(({passengerDetails}: any) => {
      const passengerType = passengerDetails.type.toLowerCase() as keyof PaxCount;
      data[passengerType] = passengerDetails.quantity;
    });
    setPaxCount(data);
  }

  const handleInputChange = (index: number, name: string, value: any) => {
    setIsFormValid(false);
    setShowInvalidErrorMessage(false);
    setMatchedSameName(false);
    setIsUNMR(false);
    setCheckboxValidation(false);
    const updatedTravellers = [...travellers];
    if (name !== "memberships") {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        [name]: value,
        errors: { ...updatedTravellers[index].errors, [name]: false }
      };
    }
    else {
      const [values] = value;
      if (values.programId) {
        updatedTravellers[index] = {
          ...updatedTravellers[index],
          [name]: value,
          errors: { ...updatedTravellers[index].errors, ['programId']: false }
        };
      }
      if (values.number) {
        updatedTravellers[index] = {
          ...updatedTravellers[index],
          [name]: value,
          errors: { ...updatedTravellers[index].errors, ['programNumber']: false }
        };
      }
    }
    
    if (['dayOfBirth', 'monthOfBirth', 'yearOfBirth'].includes(name)) {
      if (updatedTravellers[index]['dayOfBirth'] != '' && updatedTravellers[index]['monthOfBirth'] != '' && updatedTravellers[index]['yearOfBirth'] != '') {
        updatePaxRequest(index, updatedTravellers[index]);
        //setIsPaxUpdated(prevState => !prevState);

      }
    }
    setTravellers(updatedTravellers);
  };

  const handleFormSubmit = (e: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    if ((!loader) && validateForm()) {
      setLoader(true)
      let payload = travellers.map((traveller, index) => {
        let data: TravellerPayload = {
          title: traveller.title,
          paxType: traveller.paxType,
          firstName: traveller.firstName,
          middleName: traveller.middleName,
          lastName: traveller.lastName,
          gender: traveller.gender,
          dateOfBirth: `${traveller.dayOfBirth}${traveller.monthOfBirth}${(traveller.yearOfBirth).toString().slice(-2)}`,
          addOns: getAddOns(traveller, getFlightsFromSegments(segments), selectedSeats),
          memberships :traveller.memberships
        };
        if (index === 0) {
          data = {
            ...data,
            phone: traveller.mobileNumber,
            type: 'Mobile',
            email: traveller.email,
          };
        }
        return data;
      });

      let paymentDetails: any = {};
      let paymentOPtion = 'CreditCard';
      if (paymentRef.current && paymentMethod == paymentlabel.FULLPAYMENT) {
            paymentDetails = paymentRef.current.getPaymentDetails();
            paymentDetails["expiryMonth"] = paymentDetails.expiryDate.split("/")[0];
            paymentDetails["expiryYear"] = paymentDetails.expiryDate.split("/")[1];
            paymentDetails["number"] = paymentDetails.number.replace(/\s+/g, '')
            // delete paymentDetails.expiryDate;
      }
      if (virtualCreditcardData!=null && paymentMethod == paymentlabel.UPLIFTPAYMENT) {
            paymentDetails = translateUpliftCardData(virtualCreditcardData);
            paymentOPtion = 'CreditCard';
      }

      if (virtualCreditcardData == null && paymentMethod == paymentlabel.UPLIFTPAYMENT) {
        setLoader(false);
      }

      if(priceData.giftCardTotal >= priceData.itineryTotal){
        paymentOPtion = 'Cheque';
      }

      const updatedGiftCardArray = giftCardPurchase.map(({ successMessage, loading,error, ...rest }: any) => rest);
        const finalPayload = {
          fareId: itineraryFareState.id,
          "agency": "C6099",
          "agent": "manon2",
          passengers: payload,
          payments: [{
            type: paymentOPtion,
            paymentMethod:paymentMethod,
            ...(paymentOPtion !== 'Cheque' && { CreditCard: paymentDetails })
          }],
          giftCard:updatedGiftCardArray,
          priceData:priceData,
          promo:bookPromoCodeData
        }
        // if(priceData.giftCardTotal > 0){

        //   preAuthGiftCardAPI(giftCardPurchase);
        // }
        
        bookWithPaymentAPI(finalPayload, setLoader);
        // console.log("preAuthStatus in Booking file",NewPreAuthStatus);
        // if(NewPreAuthStatus){
        //   bookWithPaymentAPI(finalPayload);
        // } 
        // else{
        //   ErrorHandler('Booking Failed !', 'Technical error occur. Pre Auth Call fail.',null , 'Ok',  'warning','alertModal')
        // } 
      }
  };

  const translateUpliftCardData = (virtualCreditcardData: any) => {
    let upliftData;
    const { contact } = virtualCreditcardData;
    if (process.env.IS_DEV) {
      upliftData = {
        "nameOnCard": "Homer",
        "number": "4444333322221111",
        "cvv": "737",
        "streetAddress": "123 Main Street",
        "country": "Canada",
        "city": "Toronto",
        "province": "Ontario",
        "postalCode": "M5V 1A1",
        "expiryMonth": "03",
        "expiryYear": "2030"
      }
    }
    else {
      upliftData = {
        "nameOnCard": virtualCreditcardData.name_on_card,
        "number": virtualCreditcardData.card_number,
        "cvv": "123",
        "streetAddress": contact.street_address,
        "country": contact.country,
        "city": contact.city,
        "province": contact.region,
        "postalCode": contact.postal_code,
        "expiryMonth": virtualCreditcardData.expiration_month <= 9 ? `0${virtualCreditcardData.expiration_month}`.toString() : virtualCreditcardData.expiration_month.toString(),
        "expiryYear": virtualCreditcardData.expiration_year.toString()
      }
    }
    return upliftData;
  }
  const validateUpliftForm = () => {
    setIsFormValid(true);
    setShowInvalidErrorMessage(false);
    setMatchedSameName(false);
    setIsUNMR(false);
    setCheckboxValidation(false);
    let travellerData: TravellerData[] = JSON.parse(JSON.stringify(travellers)); // Deep copy to avoid direct state mutation
    let allValid = true; // Flag to check if all travellers are valid
  
    const validatedTravellers = travellerData.map((traveller, index) => {
      const isFirstTraveller = index === 0;
      const isValid = validateTraveller(traveller, isFirstTraveller);
      if (!isValid && allValid) {
        allValid = false;
      }
      return traveller;
    });
    // Validate correct fields
    if(!allValid){
      setTravellers(validatedTravellers);
      setShowInvalidErrorMessage(true);
      setIsFormValid(false);
      setIsUNMRAccepted(false);
      return allValid;
    }
    return allValid;
  }
  
  const validateForm = () => {
    setIsFormValid(true);
    setShowInvalidErrorMessage(false);
    setMatchedSameName(false);
    setIsUNMR(false);
    setCheckboxValidation(false);
    let travellerData: TravellerData[] = JSON.parse(JSON.stringify(travellers)); // Deep copy to avoid direct state mutation
    let allValid = true; // Flag to check if all travellers are valid
  
    const validatedTravellers = travellerData.map((traveller, index) => {
      const isFirstTraveller = index === 0;
      const isValid = validateTraveller(traveller, isFirstTraveller);
      if (!isValid && allValid) {
        allValid = false;
      }
      return traveller;
    });
    // Validate correct fields
    if(!allValid){
      setTravellers(validatedTravellers);
      setShowInvalidErrorMessage(true);
      setIsFormValid(false);
      setIsUNMRAccepted(false);
      ErrorHandler('Fill required information', 'Please fill all the required fields', null, 'Ok', 'warning', 'alertModal', scrollToTop);
      return allValid;
    }
    // Validate same names
    allValid = validateSameName(travellerData);
    if(!allValid){
      setMatchedSameName(true);
      setIsFormValid(false);
      setIsUNMRAccepted(false);
      return allValid;
    }
    allValid = validateUnaccompaniedMinor(travellerData);
    if(!allValid && !isUNMRAccepted){
      setIsUNMR(true);
      setIsFormValid(false);
      return allValid;
    }
    return allValid;
  };

  const validateUnaccompaniedMinor = (travellerData: TravellerData[]) => {  
    if(isUNMRAccepted){
      return true;
    }
    const adultTravellers = travellerData.filter((traveller: TravellerData) => {
      return traveller.paxType?.toUpperCase() === PASSENGERTYPE.ADULT?.toUpperCase();
    })
    let paxCount = adultTravellers.filter((traveller: TravellerData) => {
      const {dayOfBirth,monthOfBirth,yearOfBirth} = traveller;
      const dob = `${dayOfBirth}${monthOfBirth}${yearOfBirth}`;
      const departureDate = segmentsState[0]?.flights[0]?.departureDateTime;

      return calculateAgeAtDeparture(dob, departureDate) >= 18;
    });
    return paxCount.length !== 0;
  }

const validateSameName = (travellers: TravellerData[]) => {
    const nameCounts = travellers.reduce((acc: any, traveller) => {
    const firstName = traveller.firstName.trim().toLowerCase();
    const middleName = traveller.middleName?.trim().toLowerCase() || '';
    const lastName = traveller.lastName.trim().toLowerCase();
    const fullName = `${firstName} ${middleName} ${lastName}`.trim();
    acc[fullName] = (acc[fullName] || 0) + 1;
    return acc;
  }, {});

  const commonNames = Object.keys(nameCounts).filter(name => nameCounts[name] > 1);
  return commonNames.length === 0;
};
  

  const validateTraveller = (traveller: TravellerData, showContactDetails: boolean) => {
    const { title, firstName, lastName, email, dayOfBirth, monthOfBirth, yearOfBirth, mobileNumber, gender, paxType, memberships } = traveller;
    const errors = { ...traveller.errors }; 
    errors.title = !title;
    errors.firstName = !firstName;
    errors.lastName = !lastName;
    errors.dayOfBirth = !dayOfBirth;
    errors.monthOfBirth = !monthOfBirth;
    errors.yearOfBirth = !yearOfBirth || checkPaxAge(dayOfBirth, monthOfBirth, yearOfBirth, paxType);
    errors.gender = !gender;
    if(dayOfBirth && monthOfBirth && yearOfBirth && paxType?.toUpperCase() === PASSENGERTYPE.INFANT.toUpperCase()){
      errors.isFutureDateForInfant = isFutureDate(dayOfBirth, monthOfBirth, yearOfBirth)
    }

    if (memberships) {
      const [memberShipData]: any = memberships;
      if (memberShipData?.number && !memberShipData?.programId) {
        errors.programId = !memberShipData.programId
      }
      else if (memberShipData?.programId && !memberShipData?.number) {
        errors.programNumber = !memberShipData.number
      }
    }
    
    if (showContactDetails) {
      errors.email = !email;
      errors.email = !emailValidation(email);
      // errors.mobileNumber = !mobileNumber || (mobileNumber && !isValidPhoneNumber(mobileNumber));
      errors.mobileNumber = !mobileNumber;
    }
    const isValid = !Object.values(errors).includes(true);
  
    traveller.errors = errors;  
    return isValid;
  };  

  const checkPaxAge = (day: any, month: any, year: any, paxType: string) => {
      const dob = `${day}${month}${year}`;
      const departureDate = segmentsState[0]?.flights[0]?.departureDateTime;
      let ageAtDeparture = calculateAgeAtDeparture(dob, departureDate);
      if(paxType === PASSENGERTYPE.ADULT) return ageAtDeparture < 12;
      if(paxType === PASSENGERTYPE.CHILD) return (ageAtDeparture < 2 || ageAtDeparture > 11);
      if(paxType === PASSENGERTYPE.ADULT) return ageAtDeparture >= 2;
  }

 
  useEffect(() => {
    if (!segmentsState) return;

    const allCarriers: any = [];
    const travelData = segmentsState.map(segment => {
      
      const carriers = segment.flights[0].carrier;
      allCarriers.push(carriers);

        return {
        departureCity: segment.flights[0].departureAirportCity,
        departureAirport: segment.flights[0].departureAirport,
        arrivalCity: segment.flights[segment.flights.length - 1].arrivalAirportCity,
        arrivalAirport: segment.flights[segment.flights.length - 1].arrivalAirport
      }
    });

    setAllSegmentCarrier(allCarriers);
    // const travelData = segmentsState.map(segment => ({
    //   departureCity: segment.flights[0].departureAirportCity,
    //   departureAirport: segment.flights[0].departureAirport,
    //   arrivalCity: segment.flights[segment.flights.length - 1].arrivalAirportCity,
    //   arrivalAirport: segment.flights[segment.flights.length - 1].arrivalAirport
    // }));

    const firstSegment = segmentsState[0]?.flights?.[0];
    const lastSegment = segmentsState[segmentsState.length - 1]?.flights?.[0];
    
    setLastTravelDate(moment(lastSegment?.departureDateTime).format("YYYY-MM-DD"));
   
    setDepartureDate(moment(firstSegment?.departureDateTime).format("MMMM DD, YYYY"));
    setArrivalDate(moment(lastSegment?.arrivalDateTime).format("MMMM DD, YYYY"));

    setSegmentData(travelData);
  }, [segmentsState]);

  const getCountDisplayValue = () => {
    let displayValue = "";
    Object.keys(paxCount).forEach((key) => {
      const paxKey = key as keyof typeof paxCount;
      if (paxCount[paxKey] !== 0) {
        displayValue += `${paxCount[paxKey]} ${convertCamelCase(key)} `;
      }
    });
    return displayValue.trim();
  };

  const handleOtherPaymentChange = (value: string) => {
    handlePaymentMethod(value);
  };

  const onCardInfoUpdate = (carddata:any) => {
    if(carddata){
      setVirtualCreditcardData(carddata);
    }
  }
  const getMembershipData=(traveller:any)=>{
    if (traveller?.programId || traveller?.number) {
      let associatedCarreiers:any = [];
      let selObj:any = frequentFlyerData.find((x:any) => x.carrierCode == traveller?.programId);
      let availObj = selObj?.carrier

      if (availObj) {
        availObj.forEach((e2:any) => {
          if (e2 !== traveller?.programId) {
            associatedCarreiers.push(e2);
          }
        })
      }
      
      return {
        number: traveller?.number,
        programId: traveller?.programId,
        associatedCarriers: associatedCarreiers
      }
    }
    return {}
  }

  const handleIsFormValid = (FormValid:boolean) =>{
    setIsFormValid(FormValid);
  }
  
  return (
    <>
      <Stepper page="BOOKING" />
      <Box className="inOutContent booking_headerBox">
        <Container>
          <Typography className='booking_headerTilte'>
            {segmentData && segmentData.map((item) => {
              return (<>
                {`${item.departureCity} (${item.departureAirport}) - ${item.arrivalCity} (${item.arrivalAirport}) | `}
              </>)
            })}
          </Typography>
          <Typography className='booking_headerSubTilte'>
            {triptype?.label == TRIPTYPE.ONEWAY ? t("One Way") : triptype?.label == TRIPTYPE.ROUNDTRIP ? t("Round Trip") : t("Multi City")} | {departureDate} - {arrivalDate} | {getCountDisplayValue()}</Typography>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={4} className='Booking_wrapper'>
          <Grid item xs={12} md={8}>
            {/* Left Section */}
            <Box className="Booking_TitleBox">
              <GroupsIcon className='Booking_TitleIcon' />  <Typography className="Booking_SecondaryTitle">{t('Traveller Information')} </Typography>
            </Box>
            <Box className="Booking_TravellerCard">
              <form>
                {travellers.map((traveller, index) => (
                  <TravellerForm
                    key={index}
                    travellerNumber={index}
                    formData={traveller}
                    onInputChange={handleInputChange}
                    showContactDetails={index === 0}
                    frequentFlyerData={frequentFlyerData}
                    segmentsState={segmentsState}
                    getMembershipData={getMembershipData}
                  />
                ))}
              </form>
            </Box>
            <CustomizeYourBooking
              segments={segments}
              itineraryFare={itineraryFareState}
              updateSelectedSeat={updateSelectedSeat}
              selectedSeats={selectedSeats}
              SeatMapAPI={SeatMapAPI}
              travellers={travellers}
              token={token}
            /> 
            {(!quoteInProgress && quoteRequest?.quoteData) ?
              <InsuranceForPax
                quoteResults={quoteRequest.quoteData}
                provinceChangeCallback={(p: any) => handleProvinceChange(p)}
                selectedProvince={quoteRequest.province}
                insuranceChangeCallback={handleInsuranceChangeCallback}
              />
              : <InsuranceLoader />}
         

        
          {/* {(promoconfig.SHOW_COUPON_CODE && !pricingInProgress && siteKeyForPromo)?
           <Box className='Booking_paymentSection'>
            <CouponCodeComponent correlationId={correlationId} promoconfig={promoconfig} lastTravelDate={lastTravelDate} allSegmentCarrier={allSegmentCarrier} changePromoCode={handlePromoCode} promoCodeData={promoCodeData} updatePromoPrice={handlePromoPrice} />
          </Box>
          :''} */}
            <Box className="Booking_TitleBox" mt={2}>
            <DollerIcon className='Booking_TitleIcon' />
                <Typography variant="h6" className='Booking_SecondaryTitle'>
                    {t('Payment')}
                </Typography>
            </Box>
            <Box className="Booking_TravellerCard Booking_Paymentoption">
             <Grid container>
              <Grid item sm={12}>
              {(promoconfig.SHOW_COUPON_CODE && !pricingInProgress)?
              <Box className=' Booking_ApplyPromoCode'>
                <CouponCodeComponent correlationId={correlationId} promoconfig={promoconfig} lastTravelDate={lastTravelDate} allSegmentCarrier={allSegmentCarrier} changePromoCode={handlePromoCode} promoCodeData={promoCodeData} updatePromoPrice={handlePromoPrice} />
              </Box>
              :''}
              </Grid>
              <Grid item sm={12}>
              {
            <Box className='Booking_GiftCard'>
              <GiftCard giftCardAPI={GiftCardAPI} giftCardResult={newGiftCardResult} availableGiftCard={handleAvailableGiftCard} priceData={priceData} correlationId={correlationId}/>
            </Box>
          }
                <PaymentWithUplift 
                  travellers={travellers}
                  onCardInfoUpdate = {onCardInfoUpdate}
                  handleOtherPaymentChange={handleOtherPaymentChange}
                  itineraryFare={itineraryFare}
                  TripInfoData = {TripInfoData}
                  segments={segments}
                  triptype = {triptype}
                  newQuoteRequest = {newQuoteRequest}
                  priceData = {priceData}
                  validateUpliftForm={validateUpliftForm}
                  handleIsFormValid={handleIsFormValid}
                  />
              </Grid>
            </Grid>
         
          {
            paymentMethod == paymentlabel.FULLPAYMENT && priceData.giftCardTotal < priceData.itineryTotal ? (
              
              <Box className='Booking_paymentSection'>
                  <PaymentComponent ref={paymentRef} countriesData={countriesData} stateAPI={stateAPI} stateData={stateData} isFormValid={isFormValid} setIsFormValid={setIsFormValid} showInvalidErrorMessage = {showInvalidErrorMessage}/>
            </Box>
            
            ):'' 
          }
          </Box>
          </Grid>
          {/* Right Section */}
          <Grid item xs={12} md={4}>
            {(itineraryFareState && segmentsState) ? <UpliftPriceComponent itineraryFare={itineraryFareState}  tripInfoRequest = {TripInfoData}/> : null}
            {(!pricingInProgress && segmentsState) ? <FlightDetails segments={segmentsState} navigateToSearch={navigateToSearch} /> : <FlightDetailsLoader />}
            {(!pricingInProgress && itineraryFareState) ? 
                <PriceSummaryComponent itineraryFare={itineraryFareState} 
                  insuranceTotal={insuranceTotalAmount}
                  insuranceBreakdown={insuranceBreakdown}
                  giftCardBreakdown ={giftCardPurchase}
                  priceSummary = {handlePriceSummaryData}
                   />
                 
              : <PriceSummaryLoader />}
          </Grid>
        </Grid>
        <Grid container spacing={4}  >
        <Grid item xs={12} md={8}>
        {showInvalidErrorMessage ? <Grid item xs={12} >
          <Alert severity="error">{t('Please scroll up to review')}</Alert>
        </Grid> : null}
        {matchedSameName ? <Grid item xs={12} >
          <Alert severity="error">{t('Passengers with same name are not allowed')}</Alert>
        </Grid> : null}
        {checkBoxValidation ? <Grid item xs={12} >
          <Alert severity="error">{t('Please select the above checkbox to proceed.')}</Alert>
        </Grid> : null}
        {isUNMR ? <Grid item xs={12} >
          <Alert severity="error">
            <Typography>{t('You are about to book an unaccompanied minor (UMNR).')}</Typography>
            <Box >
              <FormControlLabel
                control={<Checkbox checked={isUNMRAccepted} onChange={() => setIsUNMRAccepted(!isUNMRAccepted)} />}
                label={
                  <Typography>
                    {t('I agree to contact the airline.')}
                  </Typography>
                }
              />
            </Box>
          </Alert>
        </Grid> : null}
        <Grid item xs={12} className='booking_TermsandSubBtn'>
          <Box >
            <FormControlLabel
              className='booking_TermsConLinks'
              control={<Checkbox checked={isFormValid} onChange={validateForm} />}
              label={
                <Typography>
                  {t("I read and agree to redtag.ca's")}
                  <Link href="https://redtag.ca/terms"> {t('terms & conditions')}</Link> {t('and the supplier’s')}
                  <Link href="https://redtag.ca/terms"> {t('terms & conditions')}.</Link>
                </Typography>
              }
            />
          </Box>
          <Button type="submit" variant="contained" onClick={(e: SyntheticEvent) => isFormValid ? handleFormSubmit(e) : setCheckboxValidation(true)} className='booking_SubmitBtn' disabled={loader}>
            {loader ? (
              <>
                <CircularProgress size={24} sx={{ color: '#ffffff', marginRight: '10px' }} /> Loading...
              </>
            ) : (
              t('Complete Purchase')
            )}
            
          </Button>
        </Grid>
        </Grid>
        </Grid>

      </Container>
    </>
  );
};

export default BookWithPaymentComponent;
