import React, { useState } from 'react';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Box, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import FlightIcon from '@mui/icons-material/Flight';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import  FlightLogo  from '../../../../assets/icons/Icon_flight.svg';

const LogosAnimation = () => {
  const { t } = useTranslation();
  const [modalHide, setModalHide] = useState(true);
  const icons = [
    <FlightLandIcon style={{ fontSize: 80 }} />,
    <FlightTakeoffIcon style={{ fontSize: 80 }} />,
    <FlightLandIcon style={{ fontSize: 80 }} />,
    <FlightTakeoffIcon style={{ fontSize: 80 }} />,
  ];
  const imgLog = [
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ac.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/dl.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/lx.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ac.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/dl.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/lx.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ei.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/fi.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ei.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
    "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/fi.png",

  ];
  const handleModalClose = () => {
    setModalHide(false);
  }

  return (<>
    {/* <div className='LoaderContainer'>
    <div className="Loader_iconsWrapper"> */}
    {/* {icons.map((icon, index) => (
        <div
          key={index}
          className="icon"
          style={{ animationDelay: `${index * 3}s` }} // Stagger the start time for each icon
        >
          {icon}
        </div>
      ))} */}
    {/* animation old */}
    {/* {imgLog.map((icon, index) => (
        <div
          key={index}
          className="icon"
          // style={{ animationDelay: `${ 0.1}s` }} // Stagger the start time for each icon
        >
       <img src={icon} width={'50px'}/>
        </div>
      ))} */}
    {/* animation old */}



    {/* </div>
    </div> */}
    {modalHide &&
      <Box className="SearchFare_Wrapper">
        <Box className="SearchFare_LoaderWarpper">
          {/* <CloseIcon className="SearchFare_LoaderCloseIcon" onClick={handleModalClose} /> */}
          <Box className="SearchFare_LoaderInfo">
            <Box className="SearchFare_Icon">
              <img src="https://tbi-profile-management-logos.s3.ca-central-1.amazonaws.com/email-template-logos/Icon_loaderImg.png" className='SearchFare_LoaderIcon' />
            </Box>
            <Box className="SearchFare_header">
              <Typography className='SearchFare_LoaderTitle'>{t("Support available")} <span className='SearchFare_Num'>{t("24/7")}</span>!</Typography>
              <Typography className='SearchFare_LoaderTel'><CallIcon className='iconTel' /> 1-866-573-3824</Typography>
            </Box>
          </Box>
          <Box className="SearchFare_LoaderDetails">
            <Typography className='SearchFare_SubTitle'> <FlightIcon />{t("Fastest & cheapest flights")}</Typography>
            <Typography className='SearchFare_SubTitle'> <LockIcon /> {t("Secured Transmission")}</Typography>
            <Typography className='SearchFare_SubTitle'><MonetizationOnIcon />{t("Best Price Guaranteed")} </Typography>
            <Typography className='SearchFare_SubTitle'><GppGoodIcon />{t("Encrypted Storage")}</Typography>
          </Box>
          <Box className="SearchFare_LoaderBox">
            <img className="SearchFare_flightImg" src='https://tbi-profile-management-logos.s3.ca-central-1.amazonaws.com/email-template-logos/loading_flight.png' alt='Loding_flight'/>
            <Box className="SearchFare_line SearchFare_line-1"> <FilterDramaIcon/></Box>
            <Box className="SearchFare_line SearchFare_line-2"><FilterDramaIcon/></Box>
            <Box className="SearchFare_line SearchFare_line-3"><FilterDramaIcon/></Box>
            <Box className="SearchFare_line SearchFare_line-4"><FilterDramaIcon/></Box>
            <Box className="SearchFare_line SearchFare_line-5"><FilterDramaIcon/></Box>
          </Box>
        </Box>
      </Box>
    }
  </>);
};





export default LogosAnimation;
