import React, { useEffect } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import i18n from "../../../Config/LanguageConfig";

interface LanguageProps {
    handleLangChange: any,
    language: string
}

export default function LanguageSelectorDropDown({ handleLangChange, language }: LanguageProps) {

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language])

    const changeLanguageHandler = ({ target }: any) => {
        const { value } = target;
        i18n.changeLanguage(value);
        handleLangChange(value)
    }

    return (<>
        <Box width={'20%'} style={{ position: 'relative', width: '100%' }}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="En"
                value={language}
                onChange={changeLanguageHandler}
                style={{position: 'absolute',
                    top: '3px',
                    right: '4%'}}
            >
                <MenuItem value='en'>En</MenuItem>
                <MenuItem value='fr'>Fr</MenuItem>
            </Select>
        </Box>
    </>);
}