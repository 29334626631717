import React, { useEffect, useState } from 'react';
import { Confirmation, BookingSummarySkeleton } from 'gigi-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from "gigi-api-layer";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utility/constants';
import { BookingDetailsAPI, FareRulesforPNRAPI } from 'gigi-api-layer';

const BookingConfirmationComponent = () => {
    // const dispatch = useDispatch();
    // const [callSignInAPI, signInData, signInProgress, signInError]= signIn()
    const navigate = useNavigate();
    const bookingData = useSelector((state: any) => state.bookingResult.booking);
    const correlationId = useSelector((state: any) => state.search.correlationId);
    let quoteBookingData = useSelector((state: any) => state.quoteBookingResult.quoteBooking);
    let giftCardPayload = useSelector((state: any) => state.giftCardResult.giftCard);
    let recordLocator= bookingData?.recordLocator
    
    const PaymentMethod = !bookingData?.error && bookingData?.payments?.[0] ? bookingData?.payments?.[0]?.paymentMethod:'';

    // useEffect(()=>{
    //     if(signInData && signInData.hasOwnProperty('accessToken') ){
    //         navigate(ROUTES.searchWidgetPage)
    //     }
    // },[signInData])
    const [callGetBookingDetails, getBookingDetailsData, getBookingDetailsInProgress, getBookingDetailsError]= BookingDetailsAPI()
    const [faresRulesForPNR, fareRulesForPNRData, fareRulesForPNRInProgress, fareRulesForPNRError] = FareRulesforPNRAPI()

    useEffect(() => {
        if(bookingData){
            fetchBookingDetails();
            fetchFareRulesForPNR()
        }
    }, [bookingData]);

    const fetchBookingDetails = async () => {
        let payload = {
            "recordLocator": recordLocator,
            "providerId": "S1_ADT",
            "agency": "C6099",
            "agent": "manon2"
        }
        try {
            let res = await callGetBookingDetails(payload,correlationId);
            // handle the response
        } catch (error) {
            // handle the error
        }
    };

    const fetchFareRulesForPNR= async ()=>{
        let payload = {
            "PNR": recordLocator,
            "agency": "C6099",
            "agent": "manon2"
        }
        try {
            let res = await faresRulesForPNR(payload,correlationId);
            // handle the response
        } catch (error) {
            // handle the error
        }

    }


    return (
        <>
             <Confirmation getBookingDetailsData={getBookingDetailsData} PaymentMethod ={PaymentMethod} getBookingDetailsInProgress={getBookingDetailsInProgress} faresRulesForPNR={faresRulesForPNR} fareRulesForPNRData={fareRulesForPNRData} quoteBookingData={quoteBookingData} giftCardPayload={giftCardPayload}/>
        </>
    );
};

export default BookingConfirmationComponent;