import dayjs from 'dayjs';
import { CREATE_SEGMENT, onlyDateFormat, TRIPTYPE } from '../../src/utils/constant';
import { FlightDetails } from '../../src/components/common/AdvancedForm/AdvancedForm'
interface Props {
    searchWidgetHide: (hide: boolean) => void;
    searchResultData: (data: any) => Promise<void>;
    cabinType: string;
}

interface SearchParams {
    adultCount: number;
    childCount: number;
    childAge:any;
    infantCount: number;
    flyingFrom: string;
    flyingTo: string;
    fromDate: string;
    toDate: string;
    setTrip: any;
    isNonStop: boolean;
    freeBag: number;
    advancedFormRef: any;
    cabinType:string;
}

class SearchRequest {
    requestPayload: any;
    searchFormValid: boolean;
    private passengers:  { type: string, quantity: number }[] = [];
    segments:any[] = [];
    constructor( params: SearchParams, advancedFormRef: any, isFromURLPath: boolean = false) {

        const { requestPayload, searchFormValid } = this.search(params, advancedFormRef,isFromURLPath);
        this.requestPayload = requestPayload;
        this.searchFormValid = searchFormValid;
    }
    

    private addPassenger(type: string, count: number) {
        if (count > 0) {
            this.passengers.push({ type, quantity: count });
        }
    }

    private processPassengers(params: SearchParams){
         
        this.addPassenger("Adult", params.adultCount);
        this.addPassenger("Child", params.childCount);
        this.addPassenger("InfantInLap", params.infantCount);

        const totalChildCount = params.childCount+ params.infantCount;

        if(totalChildCount != params?.childAge?.length){
            this.searchFormValid = false;
        }

    }

    private processSegment(params: SearchParams){
        this.segments = [CREATE_SEGMENT(params.flyingFrom, params.flyingTo, params.fromDate)];
        if (params.setTrip === TRIPTYPE.ROUNDTRIP) {
            if (!params.flyingFrom || !params.flyingTo) {
                this.searchFormValid = false;
            }
            this.segments.push(CREATE_SEGMENT(params.flyingTo, params.flyingFrom, params.toDate));
        }
    }

    processAdvancedForm(tripType: string, advancedFormRef: any){
        if (tripType === TRIPTYPE.MULTICITY && advancedFormRef) {
            let data: FlightDetails[] = advancedFormRef || [];
            if(data.length == 0) this.searchFormValid = false;
            data.forEach(({ departingFromCode, goingToCode, dateRange }) => {

                if (!departingFromCode || !goingToCode) {
                    this.searchFormValid = false;   
                }

                const modifiedDateRange = onlyDateFormat(dateRange);
                this.segments.push(CREATE_SEGMENT(departingFromCode, goingToCode, modifiedDateRange));
            });
        }
    }

    processDates(params: any,advancedFormRef:any) {
        let flag=true;
        if (advancedFormRef?.length) {
            const data = advancedFormRef?.map((formData: any, index: number) => {
                let invalidDate = false;
                let currentDate = new Date(formData.dateRange);
                let firstDate=new Date(params?.fromDate)

                advancedFormRef?.slice(index + 1).forEach((prevFormData: any) => {
                    let prevDate = new Date(prevFormData.dateRange);
                    if (firstDate > currentDate||prevDate < currentDate) {
                        invalidDate = true;
                        flag = false
                    }
                });
            });
            return flag;
        }
    }

    public search(params: SearchParams, advancedFormRef: any, isFromURLPath: boolean) {
        this.searchFormValid = true;
        this.processPassengers(params)
        this.processSegment(params);
        this.processAdvancedForm(params.setTrip, advancedFormRef);
        if(params.setTrip == TRIPTYPE.MULTICITY && isFromURLPath){
            this.processDates(params,advancedFormRef)
        }
        let obj: any = {
            "agency": "C6099",
            "tripType": params.setTrip,
            "cabin": params.cabinType,
            "passengers": this.passengers,
            "segments": this.segments,
            "maxStops": params.isNonStop ? "0" : "2",
            "freeBagRequired": params.freeBag > 0,
            "preferredAirlines": []
        };
       
        return {requestPayload:obj,searchFormValid: isFromURLPath ? params.setTrip == TRIPTYPE.MULTICITY ? this.processDates(params,advancedFormRef) : true : this.searchFormValid};

    }
}

export function ComposedSearchRequestURL(payload: any, correlationId: string) {
    const travelClassMapping: any = {
        'Economy': 'Y',
        'PremiumEconomy': 'S',
        'Business': 'C',
        'FirstClass': 'F'
    };

    const adults = payload.passengers.filter((p: any) => p.type === 'Adult').reduce((acc: any, p: any) => acc + p.quantity, 0);
    const tripType = payload.tripType?.toLowerCase() === "return" ? "roundtrip" : payload.tripType;
    const travelClass = travelClassMapping[payload.cabin];    
    let legs = payload.segments.map((segment: any) => {
        return `${segment.departureLocation};${segment.arrivalLocation};${segment.departureDate.split('T')[0]}`;
    });

    const childAges = payload.childAge.map((age: any) => `&ages[]=${age}`).join('');
    
    let queryParams = `adults=${parseInt(adults)}`;
    queryParams += `&children=${payload.childAge?.length}`;
    queryParams += `&trip=${tripType}`;
    queryParams += `&preferedClass=${travelClass}`;
    queryParams += `&viewtype=R`;
    queryParams += `&lang=en`;

    legs.forEach((leg: any) => {
        queryParams += `&leg[]=${leg}`;
    });

    queryParams += childAges;
    queryParams += `&uid=${correlationId}`;

    return queryParams;
}

export default SearchRequest;
