export const frenchMessages = {
    // Search Page
    "Booking": "Réservation",
    "Confirmation": "Confirmation",
    "Round Trip": "Aller-Retour",
    "One Way": "Aller-Simple",
    "Multi City": "Multi-Destinations",
    "Economy": "Économie",
    "Premium Economy": "Économie Supérieure",
    "Business Class": "Affaires Classe",
    "First Class": "Première Classe",
    "Departure dates": "Dates de départ",
    "Adults": "Adultes",
    "Adult": "Adulte",
    "Children": "Enfants",
    "Search": "Recherche",
    "Filter": "Filtre",
    "Clear All": "Effacer",
    "From": "Du",
    "to": "au",
    "Airlines": "Transporteurs",
    "Email address": "Adresse courriel",
    "Operated by": "Opéré par",
    "or from": "ou du",
    "Flight Details": "Détails de l’itinéraire",
    "Add Flight": "Ajouter Le Vol",
    "fare": "tarif",
    "Layover": "Halte",
    "Price Range": "Échelle des prix",
    "Select Flight": "Sélectionnez un vol",
    "Departing From": "Au départ de",
    "Going To": "À destination de",
    "No options": "Aucune option",
    "Best Departing Flights": "Meilleurs vols de départ",
    "Best": "Meilleurs",
    "Departing": " de départ",
    "Flights": "vols",
    "Ranked based on price and convenience": "Classé selon le prix et la commodité",
    "Recommended": "Recommandé",
    "Stop": "Escale",
    "stop": "escale",
    "Only": "Seulement",
    "Stay updated with the latest travel deals": "Restez informé des dernières offres de voyage",
    "Get Updates": "Recevoir les mises à jour",
    "SEGMENT": "SEGMENT",
    "Enter date": "Saisissez la date",
    "Remove Last Flight": "Retirer Le Dernier Vol",
    "in": "dans",
    "NonStop": "Direct",
    "minimumValue": "Valeur minimale",
    "maximumValue": "Valeur maximale",
    "count": "Nombre",
    "Baggage Allowance": "Franchise de bagages",
    "Clear": "Effacer",
    "Timings": "Horaires",
    "Take-off from": "Décollage de",
    "Travel Time": "Durée du voyage",
    "Flight Duration": "Durée du vol",
    "Layover Airports": "Aéroports d'escale",
    "Airports": "Aéroports",
    "Help us improve your booking experience": "Aidez-nous à améliorer votre expérience de réservation",
    "Give feedback": "Donnez votre avis",

    // Branded fares
    "Cabin": "Cabine",
    "Bags": "Bagages",
    "PremiumEconomy": "ÉconomieSupérieure",
    "BusinessClass": "AffairesClasse",
    "FirstClass": "PremièreClasse",
    "Select your fare": "Choisissez votre tarif",
    "Flexibility": "Flexibilité",
    "Select": "Sélectionner",
    "Seat": "Siège",
    "Priority": "Priorité",

    // Payment Confirmation
    "Title": "Titre",
    "None": "Aucun",
    "MR": "M",
    "MS": "MME",
    "MRS": "MME",
    "First Name": "Prénom",
    "Middle Name": "Deuxième nom",
    "Last Name": "Nom de famille",
    "Date Of Birth": "Date De Naissance",
    "Day": "Jour",
    "Month": "Mois",
    "Year": "An",
    "Gender": "Genre",
    "Male": "Masculin",
    "Female": "Féminin",
    "Email": "Courrier",
    "Enter phone number": "Entrez le numéro de téléphone",
    "Frequent Flyer Program": "Programme de dépliants fréquents",
    "Expiry Date": "Date d'expiration",
    "CVV Code": "Code CVV",
    "City": "Ville",
    "Flight": "Vol",
    "Price Summary": "Sommaire du prix",
    "Taxes and fees": "Taxes et frais",
    "Passenger": "Passenger",
    "selected": "sélectionné",
    "Total": "Total",
    "Traveller Details": "Détails Des Voyageurs",
    "Traveller": "Voyageurs",
    "Fare type": "Type de tarif",
    "of": "de",

    // Booking Confirmation
    "Departure Date": "Date de départ",
    "Departure": "Départ",
    "Return": "Aller-retour",
    "Not Available": "Indisponible",
    "Please scroll up to review":
        "Veuillez faire défiler vers le haut pour examiner les messages d'erreur et mettre à jour les données en conséquence.",
    "Please select the above checkbox to proceed.":
        "Veuillez sélectionner la case à cocher ci-dessus pour continuer.",
    "You are about to book an unaccompanied minor (UMNR).":
        "Vous êtes sur le point de réserver une mineure non accompagnée (UMNR). Vous devez contacter le transporteur pour vérifier si votre passager être accepté à bord et s'il y a des procédures obligatoires Cela doit être suivi.",
    "I agree to contact the airline.":
        "J'accepte de contacter la compagnie aérienne. Continuez et réservez.",
    "Remove": "Retirer",

    "Traveller Information": "Informations du voyageur",
    "Program Number": "Numéro du programme",
    "Protect Your Travel Investment with our Insurance Plans": "Protégez votre investissement de voyage avec nos plans d’assurance",
    "Coverage valid for Canadian residents only": "Couverture valable uniquement pour les résidents canadiens.",
    "Customize Your Booking": "Personnalisez votre réservation",
    "Choose seats": "Choisissez vos sièges",
    "Show Seatmap": "Afficher le plan des sièges",
    "Select Payment option": "Choisir une option de paiement",
    "Pay the full amount": "Payer le montant total",
    "Pay Monthly": "Payer mensuellement",
    "Payment Details": "Détails du paiement",
    "We Use Secure Transmission & Protect Your Personal Information": "Nous utilisons une transmission sécurisée et protégeons vos informations personnelles",
    "We accept all major credit cards": "Nous acceptons toutes les cartes de crédit principales",
    "Name On Card": "Nom sur la carte",
    "Credit Card Number": "Numéro de carte de crédit",
    "Billing Address": "Adresse de facturation",
    "Street Name And House No": "Nom de la rue et numéro de maison",
    "Country": "Pays",
    "State": "État",
    "Province": "Province",
    // "State / Province": "État / Province",
    "Postal Code": "Code postal",
    "I read and agree to redtag.ca's terms & conditions and the supplier’s terms & conditions.":
        "J’ai lu et j’accepte les conditions générales de redtag.ca et du fournisseur.",
    "Complete Purchase": "Finaliser l'achat",
    "Full Flight Details": "Détails complets du vol",
    "Flight Price": "Prix du vol",
    "Base price": "Prix de base",
    "Total price": "Prix total",
    "Full price breakdown": "Détail complet du prix",
    "Confirmation will be sent here": "La confirmation sera envoyée ici",
    "Seat selected": "Siège sélectionné",
    "Seatmap Key": "Légende du plan des sièges",
    "middle": "milieu",
    "Add Seat": "Ajouter un siège",
    "Skip": "Passer",
    "Halte": "Escale",
    "Filter & Sort by": "Filtrer et trier par",
    "Thank you for booking with": "Merci d'avoir réservé avec",
    "Your flight booking is currently": "Votre réservation de vol est actuellement.",
    "You have declined travel insurance.": "Vous avez refusé l’assurance voyage.",
    "Important Information": "Informations importantes",
    "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.":
        "Veuillez ne pas essayer de réserver à nouveau, car cela pourrait entraîner la duplication de votre réservation et du paiement. Les prix et la disponibilité sont susceptibles de changer et ne sont pas garantis tant que le paiement n'a pas été traité.",
    "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).":
        "Veuillez vérifier que tous les détails de votre réservation sont corrects et que tous les noms des passagers correspondent exactement à ceux figurant sur vos passeports valides.",
    "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.":
        "Les tarifs et la disponibilité sont sujets à changement et ne sont pas garantis tant que le paiement n'a pas été traité. Appelez-nous au 1.866.573.3824 si vous avez des questions.",
    "You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.":
        "Vous êtes responsable de la reconfirmation de vos horaires de vol avec Air Canada au moins 24 heures avant le départ via leur site web, par téléphone ou en vous renseignant auprès de l’autorité aéroportuaire locale. Vous devez vous enregistrer au moins 3 heures avant le départ de votre vol.",
    "Terms Of Booking": "Conditions de réservation",
    "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.":
        "Votre itinéraire se compose de deux tarifs aller simple soumis à leurs propres règles et restrictions. Si l'un de vos vols est modifié ou annulé, cela ne changera pas automatiquement l'autre vol. Des frais de pénalité peuvent être appliqués pour chaque vol en cas de changements supplémentaires.",
    "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.":
        "Les taxes et frais sont inclus dans le coût total des billets. Les prix peuvent ne pas inclure (frais de bagages) ou autres frais facturés directement par la compagnie aérienne. Les tarifs ne sont pas garantis tant que le billet n’est pas émis. Les frais de service ne sont pas remboursables. Les annulations, changements d’itinéraire et de date sont soumis aux (règles tarifaires) et à nos (frais), si ces changements sont autorisés par la compagnie aérienne.",
    "Travel Insurance Details": "Détails de l’assurance voyage",
    "Manulife Policy": "Police Manuvie",
    "DECLINED": "REFUSÉE",
    "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.":
        "Vous avez REFUSÉ l’assurance voyage. L’assurance voyage est conçue pour vous offrir une expérience de voyage sans souci. Les urgences peuvent survenir n’importe où. L’assurance voyage vous protège contre les imprévus.",
    "Billing Details": "Détails de facturation",
    "Supplier": "Fournisseur",
    "Booking Code": "Code de réservation",
    "Paid - Not Ticketed": "Payé - Non émis",
    "Full Name": "Nom complet",
    "Credit Card Holder": "Titulaire de la carte de crédit",
    "Card Type": "Type de carte",
    "Payment Total": "Montant total",
    "Payment Method": "Méthode de paiement",
    "Insurance": "Assurance",
    "You have applied too many filters.": "Vous avez appliqué trop de filtres.",
    "Click": "Cliquez",
    "here": "ici",
    "to View all results": "pour voir tous les résultats.",
    "Interest-free payments also available":
        "Paiements sans intérêt également disponibles",
    "Pay monthly with": "Payer mensuellement avec",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Child's age upon return": "Âge de l’enfant au retour",
    "Multiple flights": "Vols multiples",
    "For Coverage amounts click": "Pour les montants de couverture cliquez",
    "Show Less": "Afficher moins",
    "Show More": "Afficher plus",
    "per day": "par jour",
    "Starting from": "À partir de",
    "per person": "par personne",
    "Enter a valid mobile number": "Saisissez un numéro de mobile valide",
    "Your Booking": "Votre réservation",
    "is processing please wait": "est en cours de traitement, veuillez patienter",
    "Woops": "Oups",
    "Someting went wrong": "Quelque chose s'est mal passé",
    "There was a problem processing the request": "Un problème est survenu lors du traitement de la demande",
    "Go to Home": "Aller à l'accueil",
    "Oops! Page not found": "Oups ! Page introuvable.",
    "The page you are looking for was not found.": "La page que vous recherchez est introuvable.",
    "Select a flight": "Sélectionnez un vol",
    "Passengers with same name are not allowed": "Les passagers avec le même nom ne sont pas autorisés",
    "I read and agree to redtag.ca's": "J’ai lu et j’accepte les redtag.ca",
    "terms & conditions": "termes et conditions",
    "and the supplier’s": "et ceux du fournisseur",
    "Add More": "Ajouter plus",
    "As per your Passport": "Tel qu’indiqué sur votre passeport",
    "Mobile Number": "Numéro de mobile",
    "Facilities": "Installations",
    "with": "avec",
    "Change Flight": "Changer la vol",
    "Address":"Adresse",
    "Postal":"Postal",
    "Infant": "Bébé",
    "Child": "Enfant",
}