import React, { useState, useEffect, ChangeEvent, forwardRef, useImperativeHandle } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutocompleteComponent from '../../common/Form/Autocomplete';
import DateRange from '../../common/DateRangepicker';
import { CREATE_ELEMENT } from '../../../utils/form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface MultiCityInterface {
  allowListLength?: number;
  autoSuggestDataCall: (searchValue: string) => void;
  autoSuggestData: { label: string, value: any }[];
  advancedFormData: any[];
  fromDate: any;
  setDateErrorMessage: any;
}

export interface ChildRef {
  getFormValue: () => any[];
}

export interface FlightDetails {
  departingFrom: string;
  departingFromCode: string;
  goingTo: string;
  goingToCode: string;
  dateRange: string | Date;
  errorMessage?: string;
}

const AdvancedForm = forwardRef<ChildRef, MultiCityInterface>(({
  allowListLength = 1,
  autoSuggestDataCall,
  autoSuggestData,
  advancedFormData = [],
  fromDate,
  setDateErrorMessage
}, ref) => {
  const [extendedForm, setExtendedForm] = useState<FlightDetails[]>([]);
  const [autoSuggestCity, setAutoSuggestCity] = useState<any[]>([]);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const { t } = useTranslation()

  const [forceUpdate, setForceUpdate] = useState(0);

  const [defaultForm] = useState<FlightDetails>({
    departingFrom: '',
    departingFromCode: '',
    goingTo: '',
    goingToCode: '',
    dateRange: null,
    errorMessage: ''
  });

  useEffect(() => {
    extendFormWithAdditionalFields();
  },[]);

  useEffect(() => {
    if (autoSuggestData) {
      setAutoSuggestCity(autoSuggestData);
    }
  }, [autoSuggestData]);

  useEffect(() => {
    if(fromDate){
      const updatedForm = [...extendedForm];
      for (let i = 0; i < extendedForm.length; i++) {
        const nextDate = updatedForm[i].dateRange ? new Date(updatedForm[i].dateRange) : null;

        if (nextDate && new Date(fromDate) >= nextDate) {
            updatedForm[i].errorMessage = 'Invalid Date: Must be later than the selected date';
            updatedForm[i].dateRange = null;
        } else {
            updatedForm[i].errorMessage = '';
        }
      }
      setExtendedForm(updatedForm);
      setForceUpdate(prev => prev + 1);
    }
  }, [fromDate])

  useEffect(() => {
    if (advancedFormData?.length) {
      const data = advancedFormData.map((formData, index) => {
        let invalidDate = false;
        let currentDate = new Date(formData.dateRange);

        let firstDate= new Date(fromDate);
        if (firstDate > currentDate) {
          invalidDate = true;
        }
        (!invalidDate) && advancedFormData.slice(0, index).forEach(prevFormData => {
          let prevDate = new Date(prevFormData.dateRange);
          if(prevDate > currentDate){
            invalidDate = true;
          }
        });

        return {
          departingFrom: formData.fromLabel,
          departingFromCode: formData.departingFromCode,
          goingTo: formData.toLabel,
          goingToCode: formData.goingToCode,
          dateRange: invalidDate ? "" : new Date(formData.dateRange),
          errorMessage: invalidDate ? 'Invalid Date' : ''
        }
      });

      setExtendedForm(prevState => {
        if (JSON.stringify(prevState) !== JSON.stringify(data)) {
          return data;
        }
        return prevState;
      });
    }
  }, [advancedFormData]);

  useEffect(() => {
    console.log("setForceUpdate(prev => prev + 1)",extendedForm,fromDate)
  }, [extendedForm])

  useImperativeHandle(ref, () => ({
    getFormValue: () => extendedForm,
  }));

  const handleInputChange = (index: number, field: keyof FlightDetails, value: any, code: string = "") => {
    const updatedForm = [...extendedForm];
    const codeKey = `${field}Code` as keyof FlightDetails;

    if (!updatedForm[index]) {
        updatedForm[index] = { ...defaultForm };
    }
    updatedForm[index][field] = value;

    if (code) {
        updatedForm[index][codeKey] = code;
    } else if (!value) {
        updatedForm[index][codeKey] = "";
    }

    if (field === 'dateRange') {
        const selectedDate = new Date(value);
        if (isNaN(selectedDate.getTime())) {
            updatedForm[index].errorMessage = 'Invalid Date: Please select a valid date';
            updatedForm[index].dateRange = null;
            setExtendedForm(updatedForm);
            return;
        }

        if (fromDate) {
          const fromDateObj = new Date(fromDate);
          if (fromDateObj > selectedDate) {
            setDateErrorMessage("Invalid Date: Must be earlier than the selected date");
          } else {
            setDateErrorMessage("");
          }
        }

        for (let i = 0; i < index; i++) {
            const prevDate = updatedForm[i].dateRange ? new Date(updatedForm[i].dateRange) : fromDate ? new Date(fromDate) : null;

            if (prevDate && selectedDate <= prevDate) {
                updatedForm[i].errorMessage = 'Invalid Date: Must be earlier than the selected date';
                updatedForm[i].dateRange = null;
            } else {
                updatedForm[i].errorMessage = '';
            }
        }

        for (let i = index + 1; i < updatedForm.length; i++) {
            const nextDate = updatedForm[i].dateRange ? new Date(updatedForm[i].dateRange) : null;

            if (nextDate && selectedDate >= nextDate) {
                updatedForm[i].errorMessage = 'Invalid Date: Must be later than the selected date';
                updatedForm[i].dateRange = null;
            } else {
                updatedForm[i].errorMessage = '';
            }
        }
    }

    setExtendedForm(updatedForm);
    setForceUpdate(prev => prev + 1);
};




 




  const handleFlyingToFromChange = (
    event: ChangeEvent<{}>,
    newValue: { label: string; value: any } | null,
    index: number,
    field: keyof FlightDetails
  ) => {
    if (newValue) {
      handleInputChange(index, field, newValue.label, newValue.value.code);
    }
  };

  const handleFlyingToFromInputChange = (event: ChangeEvent<{}>, newInputValue: string, index: number, field: keyof FlightDetails) => {
    if(!event)
      return;
    handleInputChange(index, field, newInputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (newInputValue) {
        const hasSpecialCharacters = /[^a-zA-Z0-9 ]/.test(newInputValue);
        if (!hasSpecialCharacters) {
          autoSuggestDataCall(newInputValue);
        }
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const extendFormWithAdditionalFields = () => {
    setExtendedForm([...extendedForm, { ...defaultForm }]);
  };

  const reduceFormWithAdditionalFields = () => {
    setExtendedForm(extendedForm.slice(0, -1));
  };

  const advancedForm = (index: number) => [
    {
      component: AutocompleteComponent,
      props: {
        gridOptions: { item: true, sm: 3, xs: 12 },
        className: 'SearchWidget__Input',
        disablePortal: true,
        id: `combo-box-departing-${index}`,
        name: 'departingFrom',
        typographyClassName: 'search_FieldTitle',
        autoSuggestOptions: autoSuggestCity,
        onChangeMethod: (e: ChangeEvent<{}>, newValue: { label: string; value: any } | null) => handleFlyingToFromChange(e, newValue, index, 'departingFrom'),
        label: t('Departing From'),
        inputValue: extendedForm[index]?.departingFrom || '',
        onInputChangeMethod: (e: ChangeEvent<{}>, newValue: string) => handleFlyingToFromInputChange(e, newValue, index, 'departingFrom'),
        iconClass: 'searchW_FilghtIcon searchW_Icon',
        Icon: FlightIcon,
      },
    },
    {
      component: AutocompleteComponent,
      props: {
        gridOptions: { item: true, sm: 3, xs: 12 },
        className: 'SearchWidget__Input',
        disablePortal: true,
        id: `combo-box-going-${index}`,
        name: 'goingTo',
        typographyClassName: 'search_FieldTitle',
        autoSuggestOptions: autoSuggestCity,
        onChangeMethod: (e: ChangeEvent<{}>, newValue: { label: string; value: any } | null) => handleFlyingToFromChange(e, newValue, index, 'goingTo'),
        label: t('Going To'),
        inputValue: extendedForm[index]?.goingTo || '',
        onInputChangeMethod: (e: ChangeEvent<{}>, newValue: string) => handleFlyingToFromInputChange(e, newValue, index, 'goingTo'),
        iconClass: 'searchW_Icon',
        Icon: LocationOnIcon,
      },
    },
    {
      component: DateRange,
      props: {
        gridOptions: { item: true, sm: 3, xs: 12 },
        value: extendedForm[index]?.dateRange || null,
        onChangeMethod: (value: any) => handleInputChange(index, 'dateRange', value),
        name: "dateRange",
        label: t("Departure dates"),
        labelCalssName: 'search_FieldTitle',
        Icon: CalendarMonthIcon,
        iconPosition: 'start',
        iconClass: 'search_dateIcon',
        dateRangeTextFieldClass: 'search_datePicker SearchWidget__Input',
        showNumberOfMonths: 2,
        allowDateRangeSelection: false,
        startDate: extendedForm[index]?.dateRange ? extendedForm[index].dateRange : null,
        errorMessage: extendedForm[index]?.errorMessage || '',

      },
    }
  ];

  const createDynamicElement = (field: object) => {
    return CREATE_ELEMENT(field);
  };

  return (
    <>
      <Box className="SearchTabPanel">
        {extendedForm.map((item, idx) => (
          <Grid container spacing={2}   key={idx} className='Search_AddFilghtWrapper'>
            {advancedForm(idx).map((elem: any, index) => (
              <React.Fragment key={index}>
                {createDynamicElement(elem)}
              </React.Fragment>
            ))}
          </Grid>
        ))}
        {extendedForm.length < allowListLength && (
          <Button onClick={extendFormWithAdditionalFields}>{t('Add Flight')}</Button>
        )}
        {extendedForm.length > 1 && (
          <Button onClick={reduceFormWithAdditionalFields}>{t('Remove Last Flight')}</Button>
        )}
      </Box>
    </>
  );
});
 
export default AdvancedForm;
