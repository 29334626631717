import React, { useEffect, useState } from 'react';
import { Typography, Grid, TextField, MenuItem, Select, FormControl, InputLabel, Alert, FormHelperText } from '@mui/material';
import FrequentFlyer from './FrequentFlyer';
import moment from 'moment';
import { calculateAgeAtDeparture, isFutureDate } from '../../../utils/util';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { PASSENGERTYPE } from '../../../utils/constant';
import { useTranslation } from 'react-i18next';

interface TravellerFormProps {
  travellerNumber: number;
  formData: TravellerData;
  onInputChange: (travellerNumber: number, name: string, value: any) => void;
  showContactDetails: boolean;
  frequentFlyerData: any[];
  segmentsState: any;
  getMembershipData: any;
}

export interface TravellerData {
  id?: string;
  title: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email?: string;
  countryCode?: string;
  mobileNumber?: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
  gender: string;
  paxType: string;
  memberships: FrequentFlyerInterface[];
  index: number;
  errors: any;
}

interface FrequentFlyerInterface {
  programId: string;
  associatedCarriers: string[];
  number: string;
}

const AdultTitle = ["MR", "MRS", "MS"];
const ChildTitle = ["MSTR", "MISS"];
const InfantTitle = ["INF"];

const TravellerForm: React.FC<TravellerFormProps> = ({ travellerNumber, formData, onInputChange, showContactDetails, frequentFlyerData, segmentsState, getMembershipData }) => {
  const { t } = useTranslation();
  const [dobError, setDobError] = useState<string | null>(null);
  const { errors = {} } = formData;
  const departureDate = segmentsState[0]?.flights[0]?.departureDateTime;
  const [titleOptions, setTitleOptions] = useState<string[]>([]);
  const [days, setDays] = useState(31);
  const [frequentFlyer, setFrequentFlyer] = useState<FrequentFlyerInterface>({
    programId: "",
    associatedCarriers: [],
    number: ""
  });

  useEffect(() => {
    // setTitleOptions(AdultTitle)
    if (formData.paxType === PASSENGERTYPE.ADULT) {
      setTitleOptions(AdultTitle);
    } else if (formData.paxType === PASSENGERTYPE.CHILD) {
      setTitleOptions(ChildTitle);
    } else if (formData.paxType === PASSENGERTYPE.INFANT || formData.paxType === 'Infant') {
      setTitleOptions(InfantTitle);
    }
  }, [formData.paxType]);

  useEffect(() => {
    if (frequentFlyer.programId || frequentFlyer.number) {
      const memberData = getMembershipData(frequentFlyer);
      onInputChange(travellerNumber, "memberships", [memberData]);
    }
  }, [frequentFlyer, travellerNumber, onInputChange, getMembershipData]);


  function getMonthDates(year: any, monthName: string) {
    const month = parseInt(moment().month(monthName).format("M"), 10) - 1;
    if (isNaN(month)) {
      throw new Error("Invalid month name");
    }
    const parsedYear = parseInt(year, 10);
    if (isNaN(parsedYear)) {
      throw new Error("Invalid year");
    }
    const dates = [];
    const startDate = moment({ year: parsedYear, month, day: 1 });
    const daysInMonth = startDate.daysInMonth();
    for (let i = 0; i < daysInMonth; i++) {
      dates.push(startDate.clone().add(i, 'days').toDate()); // Add each day
    }
    return dates.length;
  }

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (name !== 'programId' && name !== 'number') {
      onInputChange(travellerNumber, name, value);
    }
    if (name === "dayOfBirth" || name === "monthOfBirth" || name === "yearOfBirth") {
      const day = name === "dayOfBirth" ? value : formData.dayOfBirth;
      const month = name === "monthOfBirth" ? value : formData.monthOfBirth;
      const year = name === "yearOfBirth" ? value : formData.yearOfBirth;
      validateDOB(day, month, year);
    }
    if (name === 'programId' || name === 'number') {
      addFrequentFlyerDetails(name, value);
    }
  };
  const validateDOB = (day: string, month: string, year: string) => {
    if (month && year) {
      const dates = getMonthDates(year, month)
      if (day && parseInt(day, 10) > dates) {
        const isCurrentYear = JSON.stringify(new Date().getFullYear()) == year;
        setDobError(`Invalid day selected. ${month} ${year} only ${isCurrentYear ? "has" : "had"} ${dates} days`);
        setDays(dates);
        const tOut = setTimeout(() => {
          tOut && clearTimeout(tOut);
          onInputChange(travellerNumber, "dayOfBirth", "");
        }, 500)
        return;
      } else {
        setDays(dates);
      }
    }
    if (!day || !month || !year) {
      setDobError(null); 
      return;
    }

    const dob = `${day}${month}${year}`; 
    const ageAtDeparture = calculateAgeAtDeparture(dob, departureDate);

    let error = null;
    if (formData.paxType === PASSENGERTYPE.ADULT && ageAtDeparture < 12) {
      error = `An adult must be at least 12 years old. Please enter valid DOB or relaunch your search considering this passenger as a ${ageAtDeparture < 2 ? "Infant" : "Child"}.`;
    } else if (formData.paxType === PASSENGERTYPE.CHILD && (ageAtDeparture < 2 || ageAtDeparture > 11)) {
      error = `A child must be 2 to 11 years old. Please enter valid DOB or relaunch your search considering this passenger as an ${ageAtDeparture > 11 ? "Adult" : "Infant"}.`;
    } else if ((formData.paxType === PASSENGERTYPE.INFANT || formData.paxType === 'Infant') && ageAtDeparture >= 2) {
      error = `A infant must be less than 2 years old. Please relaunch your search considering this passenger as an ${ageAtDeparture > 11 ? "Adult" : "Child"}.`;
    }
    let isFutureDateEntered = isFutureDate(day, month, year);
    if (!error && formData.paxType === PASSENGERTYPE.INFANT && isFutureDateEntered) {
      error = "Infant date of birth must be prior to booking date"
    }

    setDobError(error); 
  };

  const addMobileNo = (value: any) => {
    onInputChange(travellerNumber, "mobileNumber", value);
  }

  const addFrequentFlyerDetails = (name: string, value: any) => {
    setFrequentFlyer((prevState) => ({
      ...prevState,
      [name]: name === 'programId' ? value.carrierCode : value
    }));
  };

  const handleFrequentFlyerChange = (frequentFlyers: { programId: string; number: string }[]) => {
    onInputChange(travellerNumber, "memberships", frequentFlyers);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography className='Booking_Traveller'>
        {formData.paxType?.toUpperCase() === PASSENGERTYPE.INFANT ? t('Infant') : formData.paxType} {formData.index} ({t('As per your Passport')})
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="standard" className="Booking_Label" error={errors.title}>
            <InputLabel error={errors.title}>{t('Title')} <span className="Booking_Label_red">*</span></InputLabel>
            <Select
              name="title"
              value={formData.title}
              variant="standard"
              onChange={handleInputChange}
              placeholder={t('Title')}
              required
              error={errors.title}
            >
              <MenuItem value="" disabled>{t('Title')}</MenuItem>
              {titleOptions.map(option => (<MenuItem value={option}>{t(option)}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={t("First Name")}
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            variant="standard"
            required
            className='Booking_Label'
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label={t("Middle Name")}
            name="middleName"
            value={formData.middleName}
            onChange={handleInputChange}
            variant="standard"
            className='Booking_Label'

          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label={t("Last Name")}
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            variant="standard"
            required
            className='Booking_Label'
            error={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className='booking_DOB'>{t("Date Of Birth")}</Typography>
          <Grid container spacing={2} alignItems="flex-start">
            {/* Date of Birth Fields */}
            <Grid item container xs={9} spacing={2}>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label={t("Year")}
                  name="yearOfBirth"
                  value={formData.yearOfBirth}
                  onChange={handleInputChange}
                  select
                  required
                  variant="standard"
                  className='Booking_Label'
                  error={errors.yearOfBirth || errors.isFutureDateForInfant}
                >
                  {[...Array(95)].map((_, i) => (
                    <MenuItem key={2024 - i} value={2024 - i}>
                      {2024 - i}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label={t("Month")}
                  name="monthOfBirth"
                  required
                  value={formData.monthOfBirth}
                  onChange={handleInputChange}
                  select
                  variant="standard"
                  className='Booking_Label'
                  error={!!dobError || errors.monthOfBirth}
                >
                  {['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'].map((month, i) => (
                    <MenuItem key={i + 1} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label={t("Day")}
                  name="dayOfBirth"
                  value={formData.dayOfBirth}
                  onChange={handleInputChange}
                  select
                  required
                  variant="standard"
                  className='Booking_Label'
                  error={!!dobError || errors.dayOfBirth}
                >
                  {[...Array(days)].map((_, i) => (
                    <MenuItem key={i + 1} value={(i + 1) < 10 ? `0${i + 1}` : `${i + 1}`}>
                      {(i + 1) < 10 ? `0${i + 1}` : i + 1}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* Error message for Date of Birth */}
              <Grid item xs={12}>
                {!!dobError && (
                  <Alert severity="error">
                    {dobError}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Gender Field */}
            <Grid item xs={3}>
              <FormControl fullWidth variant="standard" className='Booking_Label' required>
                <InputLabel error={errors.gender}>{t('Gender')}</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  required
                  error={errors.gender}
                >
                  <MenuItem value="" disabled>{t('Select')}</MenuItem>
                  <MenuItem value="M">{t('Male')}</MenuItem>
                  <MenuItem value="F">{t('Female')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {showContactDetails && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t("Email")}
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                placeholder={t('Confirmation will be sent here')}
                variant="standard"
                required
                className='Booking_Label'
                error={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{position: "relative"}}>
              {/* <span style={{position: "absolute", color: "red",  fontSize: "12px", left: "170px", top: "36px"}}>*</span> */}
              <PhoneInput
                placeholder={t("Enter phone number")}
                required={true}
                international={false}
                className="Booking_Label material-ui-phone-input"
                defaultCountry="CA"
                value={formData.mobileNumber}
                onChange={addMobileNo}
                limitMaxLength={true}
              />
              {errors.mobileNumber && (
                <FormHelperText style={{ color: "red", paddingLeft: "40px" }}>{t('Enter a valid mobile number')}</FormHelperText>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <FrequentFlyer frequentFlyerData={frequentFlyerData} handleInputChange={handleFrequentFlyerChange} fieldErrors={errors} />
    </div>
  );
};

export default TravellerForm;
